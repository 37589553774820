import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Layout,
  RadioButtonGroup,
  Fieldset,
  FormField,
  ErrorMessage as FieldError,
  DatePicker,
  ButtonContainer,
  Select,
  InfoIcon
} from '@vwfs-bronson/bronson-react';
import { CountryCodeType } from '@bus/models';
import { Input } from '../../../../components/Input/Input';
import { RadioButton } from '../../../../components/RadioButton/RadioButton';
import { ErrorMessage, Field, Form, FormikProps, useFormikContext } from 'formik';
import moment from 'moment';
import { PersonalInfo } from '../../../../redux/types';

export const PersonalInfoForm = (): JSX.Element => {
  const formikProps: FormikProps<PersonalInfo> = useFormikContext();
  const { t } = useTranslation();

  return (
    <Form>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/1">
            <FormField
              notion
              type="input"
              id="fieldContactSalutation"
              labelText={t('applicationPersonInfo:formFields.salutation')}
            >
              <RadioButtonGroup labelledby="fieldContactSalutation">
                {(t('applicationPersonInfo:formFields.salutationRadio', { returnObjects: true }) as any[]).map(
                  (salutation, i) => (
                    <Field
                      id={`fieldContactSalutation${i}`}
                      key={i}
                      component={RadioButton}
                      name="salutation"
                      value={salutation.key}
                      viewValue={salutation.value}
                    />
                  )
                )}
              </RadioButtonGroup>
              <ErrorMessage name="salutation" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactFirstName"
              labelText={t('applicationPersonInfo:formFields.firstName')}
            >
              <Field component={Input} name="firstName" id="fieldContactFirstName" maxLength="80" />
              <ErrorMessage name="firstName" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactLastName"
              labelText={t('applicationPersonInfo:formFields.lastName')}
            >
              <Field component={Input} name="lastName" id="fieldContactLastName" maxLength="80" />
              <ErrorMessage name="lastName" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactDateOfBirth"
              labelText={t('applicationPersonInfo:formFields.dateOfBirth')}
            >
              <DatePicker
                name="dateOfBirth"
                id="fieldContactDateOfBirth"
                dateFormat="d/m/Y"
                value={formikProps.values.dateOfBirth}
                placeholder="D/M/Y"
                maxDate={moment(Date()).format('DD/MM/yyyy')}
                onBlur={(e: string) => {
                  formikProps.setFieldTouched('dateOfBirth', true);
                  formikProps.setFieldValue('dateOfBirth', e);
                }}
                onChange={(e: string) => {
                  formikProps.setFieldTouched('dateOfBirth', true);
                  formikProps.setFieldValue('dateOfBirth', e);
                }}
              />
              <ErrorMessage name="dateOfBirth" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactPlaceOfBirth"
              labelText={t('applicationPersonInfo:formFields.placeOfBirth')}
            >
              <Field component={Input} name="placeOfBirth" id="fieldContactPlaceOfBirth" maxLength="80" />
              <ErrorMessage name="placeOfBirth" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="select"
              htmlFor="fieldContactStateOfBirth"
              labelText={t('applicationPersonInfo:formFields.stateOfBirth')}
            >
              <Field as={Select} name="stateOfBirth" id="fieldContactStateOfBirth">
                {Object.entries(CountryCodeType).map(([countryLabel, countryCode]) => (
                  <Select.Item key={`${countryCode}${countryLabel}`} value={countryCode}>
                    {`${countryCode} ${countryLabel.replace(/([A-Z])/g, ' $1').trim()}`}
                  </Select.Item>
                ))}
              </Field>
              <ErrorMessage name="stateOfBirth" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactDrivingLicenseNumber"
              labelText={t('applicationPersonInfo:formFields.drivingLicenseNumber')}
              infoIcon={
                <InfoIcon icon={'semantic-info'}>
                  {t('applicationPersonInfo:tooltips.driversLicenseNumberTooltip')}
                </InfoIcon>
              }
            >
              <Field
                component={Input}
                name="identityDocuments[0].identityDocumentId"
                id="fieldContactDrivingLicenseNumber"
                maxLength="80"
              />
              <ErrorMessage
                name="identityDocuments[0].identityDocumentId"
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactDrivingLicenseIssuer"
              labelText={t('applicationPersonInfo:formFields.drivingLicenseIssuer')}
              infoIcon={
                <InfoIcon icon={'semantic-info'}>
                  {t('applicationPersonInfo:tooltips.drivingLicenseIssuerTooltip')}
                </InfoIcon>
              }
            >
              <Field
                component={Input}
                name="identityDocuments[0].issuingAuthority"
                id="fieldContactDrivingLicenseIssuer"
                maxLength="80"
              />
              <ErrorMessage
                name="identityDocuments[0].issuingAuthority"
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="select"
              htmlFor="fieldContactDrivingLicenseCountry"
              labelText={t('applicationPersonInfo:formFields.drivingLicenseCountry')}
            >
              <Field as={Select} name="identityDocuments[0].issuingCountry" id="fieldContactDrivingLicenseCountry">
                {Object.entries(CountryCodeType).map(([countryLabel, countryCode]) => (
                  <Select.Item key={`${countryCode}${countryLabel}`} value={countryCode}>
                    {`${countryCode} ${countryLabel.replace(/([A-Z])/g, ' $1').trim()}`}
                  </Select.Item>
                ))}
              </Field>
              <ErrorMessage
                name="identityDocuments[0].issuingCountry"
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactDrivingLicenseIssuingDate"
              labelText={t('applicationPersonInfo:formFields.drivingLicenseIssuingDate')}
              infoIcon={
                <InfoIcon icon={'semantic-info'}>
                  {t('applicationPersonInfo:tooltips.drivingLicenseIssuingDateTooltip')}
                </InfoIcon>
              }
            >
              <DatePicker
                name="identityDocuments[0].issuedDate"
                id="fieldContactDrivingLicenseIssuingDate"
                dateFormat="d/m/Y"
                value={formikProps.values?.identityDocuments[0]?.issuedDate}
                placeholder="D/M/Y"
                maxDate={moment(Date()).format('DD/MM/yyyy')}
                onBlur={(e: string) => {
                  formikProps.setFieldTouched('identityDocuments[0].issuedDate', true);
                  formikProps.setFieldValue('identityDocuments[0].issuedDate', e);
                }}
                onChange={(e: string) => {
                  formikProps.setFieldTouched('identityDocuments[0].issuedDate', true);
                  formikProps.setFieldValue('identityDocuments[0].issuedDate', e);
                }}
              />
              <ErrorMessage
                name="identityDocuments[0].issuedDate"
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <ButtonContainer nav>
        <Button type="submit" inContainer>
          {t('applicationPersonInfo:continueBtn')}
        </Button>
      </ButtonContainer>
    </Form>
  );
};
