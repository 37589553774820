import { ContentSection, Heading, PageWrap, Paragraph } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LeadDataDetailsForm } from './components/LeadDataDetailsForm';
import Login from '../../components/IdentityBroker/Login';
import { tracking } from '../../tracking/tracking';

const LandingPage = (): JSX.Element => {
  const { t } = useTranslation();

  const onLeadDataSubmit = React.useCallback(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.LEAD_DATA_FORM]());
  }, []);

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.LANDING]());
  }, []);

  return (
    <PageWrap>
      <ContentSection>
        <Heading level={4}>{t('landingPage:headingText')}</Heading>
        <Paragraph>{t('landingPage:text')}</Paragraph>
        <Login isLoginEnabled={true} isLogoutEnabled={true} />
        <ContentSection>
          <Heading level={4}>{t('landingPage:leadForm:headingText')}</Heading>
          <Paragraph>{t('landingPage:leadForm:text1')}</Paragraph>
          <Paragraph>{t('landingPage:leadForm:text2')}</Paragraph>
          <LeadDataDetailsForm afterSubmitCb={onLeadDataSubmit} />
        </ContentSection>
      </ContentSection>
    </PageWrap>
  );
};

export default LandingPage;
