import { connect, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { SaveAndContinue } from '../../components/SaveAndContinue/SaveAndContinue';
import { getIsDataStorageDataLoading, getTransactionStatus } from '../../redux/selector';
import { routeByStatus, routes } from '../../routes';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ShoppingCart from '../../components/ShoppingCart/ShoppingCart';
import { RootState, Session } from '../../redux/types';
import { addSessionDataToRedux, fetchDataStorageData, setTrackAuthSuccess } from '../../redux/actions';
import { ContentSection, Spinner } from '@vwfs-bronson/bronson-react';
import { TransactionStatus } from '../../models/data-storage.model';
import { tracking } from '../../tracking/tracking';

type WithDataLoadedProps = {
  isDataStorageDataLoading: boolean;
  trackAuthSuccess: boolean;
  dataStorageData: RootState['dataStorage'];
  getDataStorageData: (transactionId: string, token: string, navigate: NavigateFunction) => any;
  addSessionDataToState: (transactionId: string, token: string | null) => void;
  session: Session;
  appError: RootState['appError'];
  permittedStatuses?: TransactionStatus[];
  transactionStatus: TransactionStatus | null;
  children: JSX.Element;
};

const _WithDataLoaded = (props: WithDataLoadedProps): JSX.Element => {
  const {
    children,
    dataStorageData,
    isDataStorageDataLoading,
    trackAuthSuccess,
    addSessionDataToState,
    getDataStorageData,
    session,
    permittedStatuses,
    transactionStatus,
    appError
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (permittedStatuses?.length && transactionStatus) {
      if (!permittedStatuses.includes(transactionStatus)) {
        navigate(routeByStatus(transactionStatus));
      }
    }
  }, [permittedStatuses, transactionStatus]);

  useEffect(() => {
    if (session?.token) {
      getDataStorageData(session.id, session.token, navigate).then(() => {
        if (trackAuthSuccess) {
          tracking.trackEvent({
            customerData: {
              loginStatus: true
            },
            event: 'interaction',
            eventInfo: [
              {
                eventType: 'Login',
                eventAction: 'Success'
              }
            ],
            core: {
              pageInfo: {
                pageName: 'home'
              },
              attributes: {
                viewChange: 'home'
              }
            }
          });
          dispatch(setTrackAuthSuccess(false));
        }
      });
    }
  }, [session]);

  useEffect(() => {
    if (appError) {
      navigate(routes.error);
    }
  }, [appError]);

  useEffect(() => {
    if (!session) {
      const query = new URLSearchParams(location.search);
      const storefrontId = query.get('id') as string;
      const token = query.get('token') as string;
      if (storefrontId && token) {
        addSessionDataToState(storefrontId, token);
      } else if (storefrontId) {
        addSessionDataToState(storefrontId, null);
      } else {
        if (!session) {
          navigate(routes.error);
        }
      }
    }
  }, []);

  return (
    <>
      {session?.id && !session?.token && <SaveAndContinue />}
      {session?.token && (
        <>
          <ContentSection>{dataStorageData ? <ShoppingCart dataStorageData={dataStorageData} /> : ''}</ContentSection>
          {dataStorageData && !isDataStorageDataLoading ? (
            children
          ) : (
            <ContentSection>
              <Spinner center />
            </ContentSection>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isDataStorageDataLoading: getIsDataStorageDataLoading(state),
    trackAuthSuccess: state.keycloak.trackAuthSuccess,
    dataStorageData: state.dataStorage,
    transactionStatus: getTransactionStatus(state),
    appError: state.appError,
    session: state.session as Session
  };
}

const mapDispatchToProps = {
  getDataStorageData: fetchDataStorageData,
  addSessionDataToState: addSessionDataToRedux
};

export const WithDataLoaded = connect(mapStateToProps, mapDispatchToProps)(_WithDataLoaded);
