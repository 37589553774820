import { CountryCodeType, PhoneCountryCodeType } from '@bus/models';
import { AppError } from '../api/appError';
import {
  ConsentData,
  Email,
  Salutation,
  CustomerData,
  DataStorageObject,
  TransactionStatus
} from '../models/data-storage.model';

export interface UIState {
  isDataStorageDataLoading: boolean;
  isDataStorageDataLoaded: boolean;
  isTlsSupported: boolean;
  isTlsCheckInProgress: boolean;
  paymentAuthorizationStatus: PaymentResult | null;
}

export interface RootState {
  dataStorage: DataStorageObject | null;

  leadFormData: LeadFormDataState;
  personalInfo: PersonalInfo;
  contactInfo: ContactInfo;
  summaryConsentsData: ConsentInfo;

  appError: AppError | null;
  session: Session | null;
  transactionStatus: TransactionStatus | null;
  ui: UIState;
  keycloak: KeycloakState;
}

export interface KeycloakState {
  customerData: KeycloakCustomerData;
  idp: string | null;
  idpHint: string | null;
  isLogged: boolean;
  trackAuthSuccess: boolean;
}

export interface Session {
  token: string | null;
  id: string;
}

export interface LeadFormDataState {
  firstName: string;
  lastName: string;
  contactData: {
    emails: Email[];
    mobilePhoneCountryCode: string;
    mobilePhoneNumber: string;
  };
  consentData: ConsentData[];
}

export interface PersonalInfo {
  salutation: Salutation;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  stateOfBirth: CountryCodeType;
  identityDocuments: CustomerData['identityDocuments'];
}

export interface ContactData {
  emails: Email[];
  mobilePhoneCountryCode: PhoneCountryCodeType;
  mobilePhoneNumber: string;
}

export interface ContactInfo {
  addresses: CustomerData['addresses'];
  contactData: ContactData;
  consentData?: [ConsentData];
}

export interface Driver {
  dateOfBirth: string;
  role: DriverRole;
}

export interface Contract {
  drivers: Driver[];
}

export enum DriverRole {
  DRIVER_ADDITIONAL = 'DRIVER_ADDITIONAL'
}

export interface ConsentInfo {
  consentData: ConsentData[];
}

export enum PaymentResult {
  AUTHORIZED = 'AUTHORIZED',
  REFUSED = 'REFUSED',
  ERROR = 'ERROR',

  // intermediate statuses
  CHALLENGED = 'CHALLENGED',
  CHALLENGE_CANCELLED = 'CHALLENGE_CANCELLED'
}

export interface KeycloakUserInfo {
  customerData: KeycloakCustomerData;
}

export interface KeycloakCustomerData {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  contactData?: {
    email?: string;
  };
}
