import React from 'react';
import { ContentSection, PageWrap, Heading, Paragraph, Card, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { tracking } from '../../tracking/tracking';

export const ConfirmationPage: React.FC = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.CONFIRMATION]());
  }, []);

  return (
    <PageWrap>
      <ContentSection pageWrap className="u-text-center">
        <Heading level={2} testId="confirmationPageHeading">
          {t('confirmationPage:heading')}
        </Heading>
        <Heading level={3}> {t('confirmationPage:subHeading')}</Heading>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <Card style={{ maxWidth: 500, border: 'none' }} className="u-block-center" footer>
              <>
                <Heading level={5} style={{ textAlign: 'center' }}>
                  {t('confirmationPage:reservationHeading')}
                </Heading>
                <Paragraph style={{ textAlign: 'center' }}>{t('confirmationPage:reservationText')}</Paragraph>
              </>
            </Card>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <Card style={{ maxWidth: 500, border: 'none' }} className="u-block-center" footer>
              <>
                <Heading level={5} style={{ textAlign: 'center' }}>
                  {t('confirmationPage:subscriptionHeading')}
                </Heading>
                <Paragraph style={{ textAlign: 'center' }}>{t('confirmationPage:subscriptionText')}</Paragraph>
              </>
            </Card>
          </Layout.Item>
        </Layout>
      </ContentSection>
    </PageWrap>
  );
};

export default ConfirmationPage;
