import React, { useState } from 'react';
import {
  PageWrap,
  ContentSection,
  Heading,
  Paragraph,
  DefinitionList,
  DataOverview,
  Spinner,
  ButtonContainer,
  Button
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { DataStorageObject, LocalConsentType } from '../../models/data-storage.model';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ConsentInfo, RootState, Session } from '../../redux/types';
import '../index.css';
import { getContactInfo, getIsDealershopDelivery, getPersonalInfo, getSummaryConsentsInfo } from '../../redux/selector';
import { AddressType, EmailType } from '@bus/models';
import { Formik } from 'formik';
import { summaryConsentsValidationSchema } from './validation';
import { SummaryConsentsForm } from './components/SummaryConsentsForm/SummaryConsentsForm';
import { confirmTransaction } from '../../api/confirmation';
import { setSummaryConsentsInfo, setTransactionStatus } from '../../redux/actions';
import { SECURITY_DEPOSIT_DISPLAYED } from '../../config';
import { tracking } from '../../tracking/tracking';
import { generateQuoteDocument } from '../../api/document-generation';
import { QuoteDispatchModal } from '../../components/QuoteDispatchModal/QuoteDispatchModal';

export interface SummaryProps {
  dataStorage: DataStorageObject | null;
  session: Session;
}

const SummaryPage: React.FC<SummaryProps> = ({ dataStorage, session }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const personalInfoFormData = useSelector(getPersonalInfo);
  const contactInfoFormData = useSelector(getContactInfo);
  const summaryConsentsData = useSelector(getSummaryConsentsInfo);

  const isDealershopDelivery = useSelector(getIsDealershopDelivery);

  const [quoteDispatchModalShown, setQuoteDispatchModalShown] = useState<boolean>(false);

  const { addresses } = contactInfoFormData;
  const dealerAddresses = dataStorage?.data?.dealerData?.addresses;
  const differentDeliveryAddressIndex = addresses?.findIndex(address => address?.addressType === AddressType.DELIVERY);
  const customerPrimaryEmailAddress = contactInfoFormData.contactData.emails.find(
    email => email.type === EmailType.PERSONAL_PRIMARY
  )?.emailAddress;

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.SUMMARY]());
  }, []);

  const getDeliveryAddressPath = () => {
    if (isDealershopDelivery && dealerAddresses && dealerAddresses?.length > 0) {
      return dealerAddresses[0];
    }
    if (differentDeliveryAddressIndex && differentDeliveryAddressIndex >= 0 && addresses && addresses?.length > 0) {
      return addresses[differentDeliveryAddressIndex];
    }
    if (addresses && addresses?.length > 0) {
      return addresses[0];
    }
  };

  const findLabel = <T,>(arr: { key: T; value: string }[], key: T) => {
    if (!Array.isArray(arr)) {
      return key;
    }
    return (
      arr.find(obj => {
        return obj.key === key;
      })?.value ?? key
    );
  };

  const salutationLabels = t('applicationPersonInfo:formFields.salutationRadio', { returnObjects: true }) as any[];

  if (!dataStorage) {
    return <></>;
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (quoteDispatchModalShown) {
      tracking.trackEvent({
        event: 'page',
        core: {
          attributes: {
            viewChange: 'Mail with quote sent'
          }
        },
        eventInfo: [
          {
            eventType: 'Save offer',
            eventAction: 'Success'
          }
        ]
      });
    }
  }, [quoteDispatchModalShown]);

  const handleQuoteDocumentGeneration = async () => {
    setIsLoading(true);

    await generateQuoteDocument({ transactionId: session?.id as string, token: session?.token as string }).finally(() =>
      setIsLoading(false)
    );
  };

  const addAdditionalConsentValues = (values: ConsentInfo): ConsentInfo => {
    values.consentData.forEach(consent => {
      if (consent.consentType === LocalConsentType.MARKETING_ADS) {
        consent.description = t('summaryPage:marketingConsent:vwGroupCheckbox') as string;
      }
      if (consent.consentType === LocalConsentType.PHONE_ADS) {
        consent.description = t('summaryPage:marketingConsent:vwBankCheckbox') as string;
      }
      if (consent.consentType === LocalConsentType.DATA_SHARING_ADS) {
        consent.description = t('summaryPage:marketingConsent:partnersCheckbox') as string;
      }
      if (consent.consentType === LocalConsentType.TERMS_AND_CONDITIONS) {
        consent.description = t('summaryPage:generalRentalConditions:textWithoutHTML') as string;
      }
    });

    return values;
  };

  return (
    <PageWrap>
      {isLoading && <Spinner fullPage />}
      <QuoteDispatchModal
        quoteDispatchModalShown={quoteDispatchModalShown}
        setQuoteDispatchModalShown={setQuoteDispatchModalShown}
        handleResendDocument={handleQuoteDocumentGeneration}
      />
      <ContentSection>
        <Heading level="4">{t('summaryPage:checkPersonalData.headline')}</Heading>
        <Paragraph>{t('summaryPage:checkPersonalData.text')}</Paragraph>
        <ContentSection.ComponentWrapper>
          <DataOverview title={t('summaryPage:subscriptionOffer.headlineText')}>
            <DefinitionList split>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.subHeaderText')}>
                {dataStorage?.data?.financialProduct?.calculation?.duration} {t('summaryPage:durationUnit')}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.monthlyMileage')}>
                {dataStorage?.data?.financialProduct?.calculation?.monthlyMileage} {t('summaryPage:mileageUnit')}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.deliveryAddress')}>
                {isDealershopDelivery && dataStorage?.data?.dealerData?.companyName} {getDeliveryAddressPath()?.street}{' '}
                {getDeliveryAddressPath()?.houseNumber} {getDeliveryAddressPath()?.zipCode}{' '}
                {getDeliveryAddressPath()?.city} {getDeliveryAddressPath()?.countryCode}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.monthlyBaseRate')}>
                {dataStorage?.data?.financialProduct.calculation?.basePriceAmount?.grossAmount}{' '}
                {t('summaryPage:currencySymbol')}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.monthlyExtrasRate')}>
                {dataStorage?.data?.financialProduct?.calculation?.monthlyRateAddOnsAmount?.grossAmount}{' '}
                {t('summaryPage:currencySymbol')}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.selectedExtras')}>
                {dataStorage?.data?.financialProduct?.calculation?.additionalProducts?.map((product, index) => (
                  <span key={index}>
                    {product.name}
                    <br />
                  </span>
                ))}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.deposit')}>
                {SECURITY_DEPOSIT_DISPLAYED}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.labelsText.monthlyTotalRate')}>
                {dataStorage?.data?.financialProduct?.calculation?.monthlyTotalRateAmount?.grossAmount}{' '}
                {t('summaryPage:currencySymbol')}
              </DefinitionList.Item>
            </DefinitionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>
        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('summaryPage:subscriptionOffer.personalData.subHeaderText')}
            buttonLabel={t('summaryPage:editButtonText')}
            buttonProps={{
              onClick: () => {
                tracking.trackEvent({
                  event: 'interaction',
                  eventInfo: [
                    {
                      linkInformation: 'Edit Personal data',
                      eventType: 'interAction',
                      eventAction: 'Success'
                    }
                  ]
                });
                navigate(routes.application.personalInfo);
              }
            }}
          >
            <DefinitionList split>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.salutation')}>
                {findLabel<typeof personalInfoFormData.salutation>(salutationLabels, personalInfoFormData.salutation)}
              </DefinitionList.Item>
              <DefinitionList.Item></DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.firstName')}>
                {personalInfoFormData.firstName}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.lastName')}>
                {personalInfoFormData.lastName}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.dateOfBirth')}>
                {personalInfoFormData.dateOfBirth}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.placeOfBirth')}>
                {personalInfoFormData.placeOfBirth}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.stateOfBirth')}>
                {personalInfoFormData.stateOfBirth}
              </DefinitionList.Item>
              <DefinitionList.Item
                title={t('summaryPage:subscriptionOffer.personalData.driverLicense.identityDocumentId')}
              >
                {personalInfoFormData.identityDocuments[0].identityDocumentId}
              </DefinitionList.Item>
              <DefinitionList.Item
                title={t('summaryPage:subscriptionOffer.personalData.driverLicense.issuingAuthority')}
              >
                {personalInfoFormData.identityDocuments[0].issuingAuthority}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.driverLicense.issuingCountry')}>
                {personalInfoFormData.identityDocuments[0].issuingCountry}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.personalData.driverLicense.issuedDate')}>
                {personalInfoFormData.identityDocuments[0].issuedDate}
              </DefinitionList.Item>
            </DefinitionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>
        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('summaryPage:subscriptionOffer.contactData.subHeaderText')}
            buttonLabel={t('summaryPage:editButtonText')}
            buttonProps={{
              onClick: () => {
                tracking.trackEvent({
                  event: 'interaction',
                  eventInfo: [
                    {
                      linkInformation: 'Edit Contact data',
                      eventType: 'interAction',
                      eventAction: 'Success'
                    }
                  ]
                });
                navigate(routes.application.contactInfo);
              }
            }}
          >
            <DefinitionList split>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.street')}>
                {addresses[0].street}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.houseNumber')}>
                {addresses[0].houseNumber}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.postalCode')}>
                {addresses[0].zipCode}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.city')}>
                {addresses[0].city}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.country')}>
                {addresses[0].countryCode}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.emailAddress')}>
                {customerPrimaryEmailAddress || contactInfoFormData.contactData.emails[0].emailAddress}
              </DefinitionList.Item>
              <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.mobileNumber')}>
                {contactInfoFormData.contactData.mobilePhoneCountryCode}{' '}
                {contactInfoFormData.contactData.mobilePhoneNumber}
              </DefinitionList.Item>
            </DefinitionList>
            {differentDeliveryAddressIndex && differentDeliveryAddressIndex >= 0 && (
              <>
                <hr />
                <header className="c-data-overview__header">
                  <h4 className="c-data-overview__title">
                    {t('summaryPage:subscriptionOffer.contactData.differentDelivery.subHeaderText')}
                  </h4>
                </header>
                <DefinitionList split>
                  <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.differentDelivery.street')}>
                    {differentDeliveryAddressIndex >= 0 && addresses[differentDeliveryAddressIndex]?.street}
                  </DefinitionList.Item>
                  <DefinitionList.Item
                    title={t('summaryPage:subscriptionOffer.contactData.differentDelivery.houseNumber')}
                  >
                    {differentDeliveryAddressIndex >= 0 && addresses[differentDeliveryAddressIndex]?.houseNumber}
                  </DefinitionList.Item>
                  <DefinitionList.Item
                    title={t('summaryPage:subscriptionOffer.contactData.differentDelivery.postalCode')}
                  >
                    {differentDeliveryAddressIndex >= 0 && addresses[differentDeliveryAddressIndex]?.zipCode}
                  </DefinitionList.Item>
                  <DefinitionList.Item title={t('summaryPage:subscriptionOffer.contactData.differentDelivery.city')}>
                    {differentDeliveryAddressIndex >= 0 && addresses[differentDeliveryAddressIndex]?.city}
                  </DefinitionList.Item>
                </DefinitionList>
              </>
            )}
          </DataOverview>
        </ContentSection.ComponentWrapper>
        <Formik
          enableReinitialize
          initialValues={summaryConsentsData}
          validationSchema={summaryConsentsValidationSchema}
          onSubmit={values => {
            setIsLoading(true);
            const valuesUpdated = addAdditionalConsentValues(values as ConsentInfo);

            confirmTransaction({
              transactionId: session.id,
              token: session.token as string,
              data: valuesUpdated
            })
              .then(res => {
                dispatch(setSummaryConsentsInfo(valuesUpdated));
                dispatch(setTransactionStatus(res.data.transaction.status));
                navigate(routes.application.payment);
              })
              .catch(() => {
                navigate(routes.error);
              })
              .finally(() => setIsLoading(false));
          }}
        >
          {({ submitForm, isValid = false }) => {
            return (
              <>
                <SummaryConsentsForm />
                <ContentSection.ComponentWrapper>
                  <Heading level={6}>{t('applicationPage:quote.headingText')}</Heading>
                  <ButtonContainer center>
                    <Button
                      inContainer
                      disabled={!isValid}
                      onClick={() => {
                        handleQuoteDocumentGeneration().then(() => setQuoteDispatchModalShown(true));
                      }}
                    >
                      {t('applicationPage:quote.generateQuoteButton')}
                    </Button>
                    <Button inContainer type="submit" onClick={submitForm} disabled={!isValid}>
                      {t('applicationPage:quote.payNowButton')}
                    </Button>
                  </ButtonContainer>
                </ContentSection.ComponentWrapper>
              </>
            );
          }}
        </Formik>
      </ContentSection>
    </PageWrap>
  );
};

function mapStateToProps(state: RootState) {
  return {
    dataStorage: state.dataStorage,
    session: state.session as Session
  };
}

export default connect(mapStateToProps)(SummaryPage);
