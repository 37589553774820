import React from 'react';
import {
  ContextNotification,
  Notification,
  Heading,
  Paragraph,
  UnorderedList,
  InfoIcon
} from '@vwfs-bronson/bronson-react';
import { PaymentModule } from '../PaymentModule/PaymentModule';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFirstInstallmentGrossAmount, getMonthlyTotalRateGrossAmount } from '../../../../redux/selector';
import { formatPrice } from '../../../../utilities/formatters';

export const PaymentInfo = (props: any): JSX.Element => {
  const { submitPaymentForm, setIsFormValid } = props;
  const { t } = useTranslation();

  const firstInstallmentAmount = useSelector(getFirstInstallmentGrossAmount);
  const monthlyTotalRateAmount = useSelector(getMonthlyTotalRateGrossAmount);

  return (
    <>
      <div id="payment-fields-container" className="u-mb-small">
        <Paragraph>
          {t('applicationPayment:dataFields.firstInstallmentAmount')}
          <InfoIcon className="u-pt-none" icon="semantic-info">
            {t('applicationPayment:dataFields.firstInstallmentAmountTooltip')}
          </InfoIcon>
          <b className="u-float-right">{formatPrice(firstInstallmentAmount)}</b>
        </Paragraph>
        <Paragraph>
          {t('applicationPayment:dataFields.depotDeGarantie')}
          <b className="u-float-right">{formatPrice(750.0)}</b>
        </Paragraph>
        <Paragraph>
          {t('applicationPayment:dataFields.monthlyTotalRateAmount')}
          <b className="u-float-right">{formatPrice(monthlyTotalRateAmount)}</b>
        </Paragraph>
      </div>

      <ContextNotification>
        <Notification visible status="info" title={t('applicationPayment:contextInfo.title')}>
          <Paragraph>{t('applicationPayment:contextInfo.paragraph1')}</Paragraph>
          <Paragraph>{t('applicationPayment:contextInfo.paragraph2')}</Paragraph>
        </Notification>
      </ContextNotification>
      <Heading level={6}>{t('applicationPayment:cardOwnerHeading')}</Heading>
      <PaymentModule submitPaymentForm={submitPaymentForm} setIsFormValid={setIsFormValid} />
    </>
  );
};
