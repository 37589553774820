import { EmailType, PhoneCountryCodeType } from '@bus/models';
import * as Yup from 'yup';
import { LocalConsentType } from '../../models/data-storage.model';

export const emailAddressValidator = Yup.string()
  .email('validation:errorMessages:incorrectEmail')
  .required('validation:errorMessages:requiredField');

export const leadDataDetailsValidation = (isTlsSupported: boolean) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'validation:errorMessages:incorrectFirstName')
      .max(80, 'validation:errorMessages:incorrectFirstName')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectFirstName'
      )
      .required('validation:errorMessages:requiredField'),
    lastName: Yup.string()
      .min(2, 'validation:errorMessages:incorrectLastName')
      .max(80, 'validation:errorMessages:incorrectLastName')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectLastName'
      )
      .required('validation:errorMessages:requiredField'),
    contactData: Yup.object().shape({
      emails: Yup.array().of(
        Yup.object().shape({
          type: Yup.string()
            .oneOf(Object.values(EmailType), 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          emailAddress: emailAddressValidator
        })
      ),
      mobilePhoneCountryCode: Yup.string()
        .oneOf(Object.values(PhoneCountryCodeType), 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      mobilePhoneNumber: Yup.string()
        .matches(new RegExp('^(0{1}[0-9]{9,}|[1-9][0-9]{8,})$'), 'validation:errorMessages:incorrectPhoneNumber')
        .required('validation:errorMessages:requiredField')
    }),
    consentData: consentsValidation(isTlsSupported)
  });
};

export const consentsValidation = (isTlsSupported: boolean) => {
  return isTlsSupported
    ? Yup.tuple([
        Yup.object().shape({
          consentType: Yup.string()
            .oneOf([LocalConsentType.DATA_PRIVACY], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          isGiven: Yup.boolean()
            .oneOf([true], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField')
        }),
        Yup.object().shape({
          consentType: Yup.string()
            .oneOf([LocalConsentType.PHONE_SUPPORT], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          isGiven: Yup.boolean()
            .oneOf([true, false], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField')
        })
      ])
    : Yup.tuple([
        Yup.object().shape({
          consentType: Yup.string()
            .oneOf([LocalConsentType.DATA_PRIVACY], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          isGiven: Yup.boolean()
            .oneOf([true], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField')
        }),
        Yup.object().shape({
          consentType: Yup.string()
            .oneOf([LocalConsentType.PHONE_SUPPORT], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          isGiven: Yup.boolean()
            .oneOf([true, false], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField')
        }),
        Yup.object().shape({
          consentType: Yup.string()
            .oneOf([LocalConsentType.ALLOW_UNENCRYPTED_EMAILS], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField'),
          isGiven: Yup.boolean()
            .oneOf([true], 'validation:errorMessages:requiredField')
            .required('validation:errorMessages:requiredField')
        })
      ]);
};
