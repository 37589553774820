import { axios } from './apiClient';

export type TlsCheckRequest = {
  email: string;
  transactionId: string;
  token: string;
};

export type TlsCheckResult = {
  tlsSupported: boolean;
};

export const checkTLS = async ({ email, transactionId, token }: TlsCheckRequest): Promise<TlsCheckResult> => {
  const response = await axios.post<TlsCheckResult>(
    `/tls-check/${transactionId}`,
    { email },
    {
      headers: {
        Authorization: token
      },
      validateStatus: e => {
        return e === 400 || e === 200;
      }
    }
  );

  if (response.status === 400) {
    return { tlsSupported: false };
  }

  return response.data;
};
