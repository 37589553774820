import { continueLaterLocalization } from '@vwfs-its/sf-sac-frontend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18n as i18nvwfs } from './resources/locales/vwfs';

const language = process.env.REACT_APP_WEBSITE_BRAND;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      vwfs: i18nvwfs
    },
    lng: language,
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

const localization = continueLaterLocalization({
  locale: 'fr',
  brandName: { BRAND_NAME: process.env.REACT_APP_WEBSITE_BRAND }
  // dataProtectionLink: 'https://www.vwfs.ie/LegalNotice.html',
});
i18n.addResourceBundle(language as string, 'sf-sac', localization, true, true);

export default i18n;
