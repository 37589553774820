import { axios } from './apiClient';
import { DataStorageObject } from '../models/data-storage.model';
import { LeadFormDataState } from '../redux/types';

export type LeadDataRequest = {
  transactionId: string;
  data: LeadFormDataState;
  token: string;
};

export const saveLeadData = async ({ transactionId, data, token }: LeadDataRequest): Promise<DataStorageObject> => {
  const response = await axios.post(`/lead-data/${transactionId}`, data, {
    headers: {
      Authorization: token
    }
  });
  return response.data;
};
