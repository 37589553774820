import { common, AppConfiguration } from './common';

export const vw: AppConfiguration = {
  ...common,
  files: {
    dataProtection: 'data-protection/Politique%20de%20protection%20des%20données%20One%20shop_VW.pdf',
    legalTerms: {
      default: 'legal-terms/legal_terms_VW.pdf'
    }
  },
  images: {
    logo: 'logo.png',
    logoSmall: 'logo-small-screen.png'
  },
  brand: 'vw'
};
