import { axios } from './apiClient';

export type GenerateQuoteRequest = {
  transactionId: string;
  token: string;
};

export type GenerateQuoteResponse = {
  status: string;
};

/**
 * Handles quote document generation. The document is sent as and attachement in e-mail to the customer.
 * There's no file generation on client side.
 */
export const generateQuoteDocument = async ({
  transactionId,
  token
}: GenerateQuoteRequest): Promise<GenerateQuoteResponse> => {
  const response = await axios.post<GenerateQuoteResponse>(
    `/quote-document/${transactionId}`,
    {},
    { headers: { Authorization: token } }
  );

  return response.data;
};
