import { AddressType, PhoneCountryCodeType } from '@bus/models';
import moment from 'moment';
import * as Yup from 'yup';
import { ContactInfo } from '../../redux/types';
import { LocalConsentType, Salutation } from '../../models/data-storage.model';
import { consentsValidation } from '../landing-page/validation';

export const personalInfoValidation = () =>
  Yup.object().shape({
    salutation: Yup.string()
      .required('validation:errorMessages:requiredField')
      .oneOf(Object.values(Salutation), 'validation:errorMessages:requiredField'),
    firstName: Yup.string()
      .min(2, 'validation:errorMessages:incorrectFirstName')
      .max(80, 'validation:errorMessages:incorrectFirstName')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectFirstName'
      )
      .required('validation:errorMessages:requiredField'),
    lastName: Yup.string()
      .min(2, 'validation:errorMessages:incorrectLastName')
      .max(80, 'validation:errorMessages:incorrectLastName')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectLastName'
      )
      .required('validation:errorMessages:requiredField'),
    dateOfBirth: Yup.string()
      .required('validation:errorMessages:requiredField')
      .test('DOB', 'validation:errorMessages:incorrectAge', value => {
        const dateValue = moment(value, 'DD/MM/YYYY', true);
        if (!dateValue.isValid()) {
          return false;
        }
        return moment().diff(dateValue, 'years') >= 18;
      }),
    placeOfBirth: Yup.string().required('validation:errorMessages:requiredField'),
    stateOfBirth: Yup.string().required('validation:errorMessages:requiredField'),
    identityDocuments: Yup.array().of(
      Yup.object()
        .shape({
          identityDocumentType: Yup.string().required(),
          identityDocumentId: Yup.string().required('validation:errorMessages:requiredField'),
          issuingAuthority: Yup.string().required('validation:errorMessages:requiredField'),
          issuingCountry: Yup.string().required('validation:errorMessages:requiredField'),
          issuedDate: Yup.string()
            .required('validation:errorMessages:requiredField')
            .test('issuedDate', 'validation:errorMessages:requiredField', value => {
              const dateValue = moment(value, 'DD/MM/YYYY', true);
              return dateValue.isValid();
            })
            .test('isAtLeastThreeYears', 'validation:errorMessages:drivingLicenseAtLeast3Years', value => {
              const dateValue = moment(value, 'DD/MM/YYYY', true);
              return moment().diff(dateValue, 'years') >= 3;
            })
        })
        .required()
    )
  });

const getAddressFieldsValidators = () =>
  Yup.object().shape({
    street: Yup.string()
      .min(2, 'validation:errorMessages:incorrectStreet')
      .max(100, 'validation:errorMessages:incorrectStreet')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš][0-9a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectStreet'
      )
      .required('validation:errorMessages:requiredField'),
    houseNumber: Yup.string()
      .max(10, 'validation:errorMessages:incorrectHouseNumber')
      .matches(
        new RegExp(
          "^[a-zA-Z0-9ÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš][a-zA-Z0-9ÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"
        ),
        {
          message: 'validation:errorMessages:incorrectHouseNumber',
          excludeEmptyString: true
        }
      ),
    zipCode: Yup.string()
      .min(5, 'validation:errorMessages:incorrectPostalCode')
      .max(5, 'validation:errorMessages:incorrectPostalCode')
      .matches(new RegExp('^[0-9]*$'), 'validation:errorMessages:incorrectPostalCode')
      .required('validation:errorMessages:requiredField'),
    city: Yup.string()
      .min(2, 'validation:errorMessages:incorrectCity')
      .max(50, 'validation:errorMessages:incorrectCity')
      .matches(
        new RegExp("^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš][0-9a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-.']*$"),
        'validation:errorMessages:incorrectCity'
      )
      .required('validation:errorMessages:requiredField'),
    countryCode: Yup.string().required('validation:errorMessages:requiredField'),
    addressType: Yup.string().required()
  });

export const contactInfoValidation = () => {
  return Yup.object().shape({
    contactData: Yup.object().shape({
      emails: Yup.array().of(
        Yup.object().shape({
          type: Yup.string(),
          emailAddress: Yup.string()
            .email('validation:errorMessages:incorrectEmail')
            .required('validation:errorMessages:requiredField')
        })
      ),
      mobilePhoneCountryCode: Yup.string()
        .oneOf(Object.values(PhoneCountryCodeType), 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      mobilePhoneNumber: Yup.string()
        .matches(new RegExp('^(0{1}[0-9]{9,}|[1-9][0-9]{8,})$'), 'validation:errorMessages:incorrectPhoneNumber')
        .required('validation:errorMessages:requiredField')
    }),
    addresses: Yup.lazy((formAddresses: ContactInfo['addresses']) => {
      const addressValidators = [getAddressFieldsValidators().required()];
      const hasDeliveryAddress = formAddresses.find(address => address?.addressType === AddressType.DELIVERY);
      if (hasDeliveryAddress) {
        addressValidators.push(getAddressFieldsValidators().required());
      }
      return Yup.tuple(addressValidators as any); // TODO: figure out type fix here for yup tuple
    })
  });
};

export const consentValidation = (isTlsSupported: boolean) => {
  return Yup.object().shape({ consentData: consentsValidation(isTlsSupported) });
};

export const tlsConsentValidation = (isTlsSupported: boolean) => {
  if (isTlsSupported) {
    return Yup.object().notRequired();
  }

  return Yup.object().shape({
    consentData: Yup.tuple([
      Yup.object().shape({
        consentType: Yup.string()
          .oneOf([LocalConsentType.ALLOW_UNENCRYPTED_EMAILS], 'validation:errorMessages:requiredField')
          .required('validation:errorMessages:requiredField'),
        isGiven: Yup.boolean()
          .oneOf([true], 'validation:errorMessages:requiredField')
          .required('validation:errorMessages:requiredField')
      })
    ])
  });
};
