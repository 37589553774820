import { AnalyticsDataLayer } from './datalayer.model';

export const initialDataLayer: AnalyticsDataLayer = Object.freeze({
  core: {
    /*
    Please put here the stage (DEV, INT, CONS, PROD) on which this site is
    */
    stagingEnvironment: process.env.REACT_APP_STAGE,

    dataLayerVersion: '2.12',

    pageInfo: {
      /*
      The values for pagename are defined in tracking specification
      document.
      */
      pageName: null,

      /*
      The device type the website is intended for.
      Type: Enum
      Mobile phone
      Tablet
      Desktop
      all
      */
      intendedCustomerDeviceType: null,

      /*
      Version or release of the site. Constant value of your choice
      which identifies best this current application version.
      */
      version: null,

      /*
      Release date of this site as a constant value. Format YYYY-MM-DD
      */
      releaseDate: null,

      /*
      Current display language.
      Type: Enum
      ISO-639-2; lower case two letter code
      Hint: Visitor can change the language while his visit (e.g. in
      Switzerland).
      */
      language: null,

      /*
      Type: Enum
      The country name of the market this site is serving
      (e.g. DE: W&I -> DE, UK: S&I -> UK).
      ISO-3166-1-alpha-2; upper case two letter code
      */
      market: null,

      /*
      Type: Enum
      Name of responsible department (DU, local-DE, local-UK)
      */
      publisher: null
    },

    category: {
      /*
      Main category of this site.
      Type: Enum
      Banking
      Charge & fuel
      Customer portal
      Digital renewal
      Financing
      Fleet
      fob
      Insurance
      Leasing
      Local customer portal
      Local portal
      Local public site
      NBW
      Rental
      Service
      */
      primaryCategory: null,

      /*
      Please further describe your journey/site *if necessary*. The
      product owner can choose a unique name.
      Examples:
      Champions Club
      OperateLease
      Short term insurance
      Service and inspection
      AutoCredit & ClassicCredit
      */
      secondaryCategory: null,

      /*
      Offered variants of the product that the customer can choose.
      E.g. basic / standard / premium variant of a contract
      */
      productVariants: ['name'],

      /*
      If your journey splits in sub processes, standalone, checkout site,
      app, ...
      Type: Enum
      app
      Full journey
      Checkout journey
      Pre-approval journey
      */
      siteType: null,

      /*
      Asset-based products
      Non-Asset-based products
      Other products
      */
      inventoryType: null,

      /*
      Classification of the result which should be achieved by the journey
      Type: Enum
      Lead
      Online contract
      */
      maturityLevel: null,

      /*
      New
      Renewal
      */
      contractType: null,

      /*
      If your journey splits in sub processes,
      e.g. PCN (Perfect-car-Neuwagen, ...), PVN, VSB. Can be chosen
      individually by POs
      */
      processType: null
    },
    attributes: {
      /*
      Type: Enum
      customer-facing-product-journey
      customer-facing-journey
      dealer-facing-product-journey
      dealer-facing-journey
      after-sales-journey
      */
      journeyType: null,

      /*
      If your page contains expandables, which once expanded do not
      change the URL. Example: On page personal data, there are
      expandables for address, account details, ... The values are
      specified in the corresponding specification document.
      */
      viewChange: null,

      /*
      Refers to the branding of the site.

      Never include design versions here: This should not be changed to
      vw6 when a new design is released. The brand is still vw.
      vwcv refers to VW Commercial Vehicles. BFF’s data model supplies
      vwn, but we expect vwcv.

      @Developers: Please fill in the correct value here based on the
      site's
      branding

      Type: Enum
      Audi
      Bentley
      Cupra
      Ducati
      Lamborghini
      MAN
      Porsche
      Scania
      Seat
      Skoda
      VW
      VWCV
      VWFS
      */
      brand: 'vw',

      /*
      The brand that is currently the focus of the portal or shop
      website. Do not confuse e.g. with the vehicle manufacturer
      */
      contextBrand: null,

      /*
      An ID for this transaction
      */
      transactionID: null,

      /*
      A descriptive name for the referrer website.
      */
      context: null,

      /*
      @Developers:
      Acceptance criteria:
      - If the viewChange relates to a selection of a FAQ please out the
      question text (not the answer text) here

      */
      faqText: null,

      SandCtype: null
    }
  },

  /*
  In case of any error situations (mainly standard errors like 401, 404, ...
  and in context of forms) an error code (if available) and the error message
  (if possible a shortened version) are placed here. If available the causing
  URL (e.g. the wrong URL in case of 404, the referring URL in case 401, ...)
  should be placed here, too.
  */
  error: {
    //Error code of application exceptions or caught errors
    errorCode: null,

    //Error message for caught errors with available message
    errorMessage: null,

    //Full URL which caused the error
    errorCausingURL: null
  },

  universally: {
    /* The number of contracts the customer has concluded
     */
    numberOfProducts: null,

    /* NPS rating of customer.
         Type: String / integer or string
    */
    nps: [
      {
        topic: null,
        rating: null
      }
    ],

    /* Additional information, e.g. about the contract
         Type: String
    */
    additionalInformation: []
  },

  /*
  This structure is filled as soon as the user provides information
  regarding any product.

  Please note that this structure is an array structure. If the customer can
  bundle his contract (i.e. get a leasing contract, a service and maintenance
  package, and a tire package, this array should have three entries. Same is
  e.g. in Customer portal if customer has more than one product.
  */
  product: [
    {
      /*
    Internal ID, especially for hybrid sales. E.g. VW marketing ID (from car
    configurator), HeyCar ID. This id is typically generated if an online
    lead occurs. With this id, a dealer can identify the corresponding car
    and close the contract with the customer.
    Idea is to store keys submitted to CRM systems here to allow finding
    out which customers actually close contract. Potentially this is a
    quoteID in UK.
    */
      productID: null,

      /*
    The main category of the current contract.
    Type: Enum
    Finance
    Insurance
    Leasing
    Service
    Other
    */
      category: null,

      /*
    The name of the product from the given list. If you cannot find a
    suitable entry for the product sold in the journey, please reach out
    to the web analytics team. Please do not add any products to this list
    on your own.

    Type: Enum
    AutoCredit
    CarSharingProtection
    ChargeAndFuel
    ClassicCredit
    Credit
    CreditProtectionInsurance
    LeasingRateInsurance
    DigitalRenewal
    ExtendedWarranty
    FinanceLease
    GuaranteedAssetProtection
    MotorInsurance
    OperateLease
    RentalCarProtection
    ServiceInspection
    ServiceLimitedMaintenance
    TestDriveProtection
    ThirdPartyProtection
    UsedCarWarranty
    TirePackage
    */
      name: null,

      /*
    Other subcategories of the product, e.g. depending on the context
    in which the product is offered
    */
      subname: [],

      /*
    Further variants of the product, e.g. basic / standard / premium
    variant of a contract which the customer has chosen
    */
      productVariants: [],

      /*
    Additions to the scope of the contract that the customer has chosen
    E.g. Glass breakage insurance, credit rate insurance, tyre insurance
    */
      productAddons: [],

      attributes: {
        /*
      Specifies the type of complete sales process

      Type: Enum
      Hybrid sales
      Online sales
      Partner sales (Dealer POS)
      */
        typeOfSale: null,

        /*
      Unit for payment frequency.
      Type: Enum
      single payment
      weekly payment
      biweekly payment
      monthly payment
      bimonthly payment
      quarterly payment
      biannual payment
      annual payment
      */
        paymentFrequency: null,

        /*
      The currency unit used for displayed prices (regular the local
      currency). The value is taken from ISO-Code 4217.
      */
        currency: null,

        /*
      E.g. monthly payment for insurance or leasing contract or an
      installment. Please provide exact (float) value
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        recurringPayment: null,

        /*
      Planned duration of contract; please use value -1 if indefinite
      Type: Integer
      */
        duration: null,

        /*
      Unit for planned duration of contract.
      Type: Enum
      WEEK
      TWO-WEEKS
      MONTH
      TWO-MONTHS
      QUARTER
      SIX-MONTHS
      YEAR
      */
        durationUnit: null,

        /*
      The start date of contract in focus.
      Type: Date (YYYY-MM-DD)
      */
        startDateOfContract: null,

        /*
      The end date of contract in focus.
      Type: Date (YYYY-MM-DD)
      */
        endDateOfContract: null,

        /*
      Only if part of the contract
      Type: Integer
      */
        yearlyMileage: null,

        /*
      For contracts with a term of significantly less than a year
      Type: Integer
      */
        contractMileage: null,

        /*
      The maximum agreed excess of the yearly mileage. This is the
      difference of the actual yearly mileage and the expected yearly
      mileage (yearlyMileage).
      Type: Integer
      */
        excessMileage: null,

        /*The unit of yearlyMileage, excessMileage or undrivenMileage.
      Should always be KILOMETERS in the european region.
      Type: Enum
      KILOMETERS,
      MILES
      */
        mileageUnit: null,

        /*
      The type of payment.

      Type: Enum

      Automatic Bank Transfer
      Credit Card
      Direct Deposit
      E-Wallet
      Mobile Payment
      */
        paymentType: null,

        /*
      The date of actual registration. Only if part of the contract.
      Type: Date (YYYY-MM-DD)
      This is a particular important value. Do not skip it due to
      management request!
      */
        registrationDateOwner: null,

        /*
      If the contract is entitled to deduct input tax (VAT).
      Type: Boolean
      */
        preTaxSubstraction: null,

        /*
      This is the repayment of the outstanding principal sum made at the
      end of a loan period, interest only having been paid hitherto.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        balloonPayment: null,

        /*
      The final amount customer has to pay to fulfill the contract.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        contractAmount: null,

        /*
      Acceptance criteria:
      The net loan amount
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.

      */
        netLoanAmount: null,

        /*
      The gross loan amount. This value is the total sum of the special
      loan amount plus all credit costs (including all interests and all
      fixed costs). It is also the amount which the customer will pay in
      the end.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        grossLoanAmount: null,

        /*
      The amount of deposit that the customer makes in the context of a
      financing product.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        downPaymentAmount: null,

        /*
      The nominal interest rate expressed as a factor applied to the net
      loan amount. It includes only the credit costs that are caused by
      the nominal interest itself.
      Type: Float
      */
        nominalInterestRate: null,

        /*
      The amount of interests caused by the nominalInterestRate over the
      entire timespan of the credit. It does not include other credit
      costs than nominal interests.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        nominalInterestAmount: null,

        /*
      The effective interest rate expressed as a factor applied to the net
      loan amount. It takes all costs for the customer in this contract
      into account.
      Type: Float
      */
        effectiveInterestRate: null,

        /*
      The deductible that is part of the contract.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */
        deductible: null,

        /*
      Single special payment.
      Type: Float
      The currency is the local currency.
      The value is stored without currency unit.
      */

        specialAmount: null,

        /*
      Additional services that are not part of the contract that the
      customer has chosen.
      Type: String
      Example:
      name: "Home delivery"
      additionalInformation: null
      name: "Pick up at dealer"
      additionalInformation: "Dealer zip 65473"
      name: "Additional mileage"
      additionalInformation: "25000"
      */
        addOns: [
          {
            name: null,
            additionalInformation: null
          }
        ],

        /*
      In the case that special conditions are granted to the customer.
      Example: "Interest-free period: 30 days"
      Multiple entries are separated by the character "|"
      Type: String
      */

        specialConditions: null,

        termination: {
          /*
        The type of contract termination:
        Extraordinary
        Ordinary
        */
          type: null,

          /*
        The customer's reason for terminating the contract.
        */
          reason: null
        }
      },

      //Aligned with BFF data model!
      vehicleModel: [
        {
          /*
      The manufacturer of the current vehicle.
      Type: Enum
      audi
      bentley
      cupra
      ducati
      man
      porsche
      scania
      seat
      skoda
      vw
      vwcv
      vwfs
      */
          manufacturer: null,

          /*
      The main name of the current car.

      Type: string, for example
      A3
      Kodiaq
      XC60
      Auris
      Golf
      */
          name: null,

          /*
      The first subcategory of the current car in manufacturers product
      program.
      Type: string
      For example
      GTI
      */
          modelVariation: null,

          /*
      The year when the model has been released.
      Type: string
      Format: YYYY
      */
          year: null,

          /*
      The second subcategory of the current car in manufacturers product
      program.
      Type: string
      For example
      Sportline
      Momentum
      Sportback
      Performance
      */
          modelLine: null,

          /*
      Long description of the vehicle model
      Type: String
      Example:
      Golf GTI Performance
      A3 Sportback 1.5 TFSI Black Edition
      */
          descriptionLong: null,

          /*
      For tracking of hybrid sales, the sales id which is handed over
      Type: String
      */
          salesID: null,

          /*
      Describes the body type of the vehicle.
      Type: Enum
      CONVERTIBLE
      COUPE
      CROSSOVER
      CABRIO
      LUXURY CAR
      SEDAN
      SPORTS CAR
      SUV
      TRUCK
      VAN
      WAGON
      */
          bodyType: null,

          /*
      Vehicle class. E.g. for leasing the class the vehicle belongs to.
      */
          category: null,

          /*
      If it is a vehicle with a special warranty (Das Weltauto,
      TradePort) then true, otherwise false
      Type: Boolean
      */
          certifiedPreOwned: null,

          /*
      The current mileage of the car
      Original value: 15388 km
      Data layer value: 15388
      Type: Integer
      */
          currentMileage: null,

          /*
      The unit of the current mileage of the car
      Type: Enum
      KILOMETERS,
      MILES
      */
          currentMileageUnit: null,

          /*
      The date when the vehicle has been registered first (In german
      "Tag der Erstzulassung").

      This value is of special importance to be tracked correctly as it
      serves as a measure for customer loyalty. Tracking this value
      correctly is of high importance for our management.
      Type: String
      Format: YYYY-MM-DD
      */
          initialRegistrationDate: null,

          /*
      The base price of current vehicle
      Type: Integer
      Unit local currency
      Original value: 15388 PLN
      Data layer value: 15388
      */
          basePrice_localCurrency: null,

          /*
      Local currency
      ISO-4217 alphabetic code upper case letters
      Example: PLN
      */
          currency: null,

          /*
      The final vehicle price (local currency). The unit is not part of
      the value.
      Type: Integer
      Unit local currency
      Original value: 15388 PLN
      Data layer value: 15388
      */
          endPrice_localCurrency: null,

          /*
      Manufacturer key number if available (typically in Germany HSN)
      Type: String
      Value for Volkswagen:
      0603
      Value for Volvo:
      1263
      Value for BMW:
      0005
      */
          manufacturerCode: null,

          /*
      Model code number if available (typically in Germany TSN)
      Type: String
      Value for VW Golf Cabriolet 1.2 TSI:
      BEK
      Value for Volvo XC 60 2.4 D AWD:
      BAR
      */
          typeCode: null,

          /*
      The base color of the current vehicle

      Enum:
      Black
      Blue
      Brown
      Green
      Grey
      Red
      Violet
      White
      Yellow
      */
          colorExterior: null,

          /*
      The type of usage

      Enum:
      Private
      Business
      Both
      */
          typeOfUse: null,

          /*
      For example, for digital renewal, please insert the model
      description of the priorModel (please refer to vehicle variable
      descriptionLong).
      */
          priorModel: null,

          /*
      The equity of the prior model
      Type: Float
      */
          equity: null,

          /*
      The condition of the vehicle.
      E.g.
      New car
      Used car
      */
          condition: null,

          engine: {
            /*
        The engines fuel type of the vehicle

        Enum:
        CNG
        Diesel
        Electric
        Hybrid
        Hydrogen
        LPG
        Petrol
        */
            fuelType: null,

            /*
        The emission value of the engine.
        Format: <nnn> g/km
        */
            emission: null,

            /*
        Transmission; e.g.
        6-speed manual
        7-speed automatic
        */
            transmission: null,

            /*
        The engines power in both units: PS and kW. E.g.
        115 PS (84 kW)
        */
            enginePower: null
          }
        }
      ]
    }
  ],

  //Aligned with BFF data model!
  dealerData: {
    /*
    The 5-digit company id which uniquely identifies the car dealer within
    the VW ecosystem.
    Type: string
    */
    companyId: null,

    /*
    The company name of the car dealer.
    Type: string
    */
    companyName: null,

    /*
    The 3-digit region id allows to group car dealers in the VW ecosystem
    by regions. The region id should be sent with every transaction to
    correctly assign transactions to regional systems.
    Type: string
    */
    regionId: null,

    /*
    The sales persons internal ID.
    Type: string
    */
    KVPS: null,
    address: {
      //Dealers address
      street: null,
      //The postal code of dealer’s address.
      zipCode: null,
      //The city of the address.
      city: null,
      /*The region the dealer is located. In Germany this would be the
      federal state.
      */
      state: null
    }
  },

  form: {
    /*The main subject of the form. Search forms are handled by the search
    element of this data layer.
    */
    type: null,

    /*The meaningful descriptive name of the form in context of the current
    site.
    */
    name: null,

    /* If required content of form fields are included here. Syntax:
    <shortened field name1>: <value1> | <shortened field name2>: <value2>
    Field names should be in English.
    */
    fieldValues: null,

    //If the form is aborted the meaningful name of last touched field.
    lastTouchedField: null,

    /*In case of user input errors, a semicolon-/whitespace separated
    list of meaningful shortened names of all erroneous fields.
    Example:
    Plate; Zip
    */
    errorFields: null
  },

  design: {
    /*
    For responsive design: Thresholds when design changes. The values are
    the Bronson breakpoints:
    xxl   (browser tab width >= 1920 px)
    xl    (browser tab width >= 1600 px)
    l     (browser tab width >= 1280 px)
    m     (browser tab width >=  960 px)
    s     (browser tab width >=  720 px)
    xs    (browser tab width >=  480 px)
    Type: string
    */
    browserResolutionBreakpoint: null
  },

  customerData: {
    /*
    Indicates whether the user is currently logged in or not
    Type: Boolean
    */
    loginStatus: null,

    /*
    The user group the customer belongs to. Specified values are:
    - private
    - business (small)
    - business (corporate)
    - business
    (the latter applies if there is no differentiation according to
    company size)
    If there is no data available the value is
    - not available
    */
    loggedInUserGroup: null,

    /*
    The year the customer was born.
    Type: Integer
    Format: YYYY
    */
    yearOfBirth: null,

    /*
    The gender of the customer
    Type: Enum
    MALE
    FEMALE
    */
    gender: null,
    /*
    The result of credit check
    Type: Enum
    success
    rejected
    delayed
    */
    ccResult: null,
    address: {
      /*If the customer provides multiple addresses, we expect the zip
      code of latest address here.
      */
      zipCode: null
    }
  },

  businessData: {
    /*
    Indicator for the size of a company. Which indicator is to use is a
    PO decision.
    E.g.:
    Employees: 78
    Annual revenue: 500000
    Type: String
    */
    companySize: null
  },

  filter: [
    {
      /*
    The descriptive name of filter criterium
    Type: String
    Specified values are:
  Rate
  Price
  Manufacturer (abbreviation Manuf)
  Vehicle model (abbreviation Model)
  Fuel type (abbreviation Fuel)
  Transmission (abbreviation Gear)
  Driving style
  Exterior color (abbreviation ExtCol)
  Equipment (abbreviation Equip)
  Initial registration (abbreviation IniReg)
  Current mileage (abbreviation CurrMile)
  Yearly mileage (abbreviation YearMile)
  Contract duration (abbreviation Duration)
  Body type (abbreviation Body)
  Condition (abbreviation Cond; values New car or Used car)
  Doors
  Downpayment

    If you are missing values please contact web analytics team.
    */
      filterName: null,

      /*
    The performed action. Either
    Initial
    Selection
    Deselection
    Change
    Result list
    Checkout
    */
      filterAction: null,

      /** The selected values for the filter criterium

     For monetary amounts (e.g.monthly rate) include 3-digit currency
     code (ISO 4217) as suffix separated by a whitespace
     */
      filterValue: []
    }
  ],

  targeting: {
    /*
    For real-time purposes in the context of A/B tests, identifier of Adobe
    Target experience.
    Type: string
    */
    pageTargetVersion: null,

    /*
    Micro steps of the customer journey, e.g.
    landing | calculator | personalData | payment | success
    Each time a new page is visited, add the page name to this array.
    If a page is visited more than one time, (Page A, page B, Page A, …),
    please attach page A again.
    Type: string

    Example:
    Customer on index.html
    Data layer value:
    ['landing']
    Example:
    Customer subsequently visits calculator page
    Data layer value:
    ['landing', 'calculator']
    */
    completeVisitPath: []
  },

  video: {
    // Only videos that the customer must explicitly start are tracked

    // Internal ID of video
    videoID: null,
    // Name of video; should be set in video tag, too
    videoName: null,
    // Length of video (in seconds).
    videoLength: null,
    // Played time of video (in seconds).
    videoViewed: null
  },

  //Aligned with BFF data model!
  dataPrivacyStatement: {
    /*
    If the customer allows a contact via postal the value is "Postal".
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Postal
    Postal (VWFS)
    Postal (Business partner)
    Type: String
    */
    allowPostalAds: null,

    /*
    If the customer allows a contact via phone the value is "Phone".
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Phone
    Phone (VWFS)
    Phone (Business partner)
    Type: String
    */
    allowPhoneAds: null,

    /*
    If the customer allows a contact via Electronic channels the value is
     "Electronic". When distinguishing between contact partners please use
    the descriptive term as Suffix (enclosed in round brackets).
    Example:
    Electronic
    Electronic (VWFS)
    Electronic (Business partner)
    Type: String
    */
    allowElectronicAds: null,

    /*
    If the customer allows a contact via mail the value is "Mail".
    When distinguishing between contact partners please use the
    descriptive term as Suffix (enclosed in round brackets).
    Example:
    Mail
    Mail (VWFS)
    Mail (Business partner)
    Type: String
    */
    allowMailAds: null,

    /*
    If the customer agrees to transfer personal data to other providers
    such as Google, Meta.
    Value:
    Personal data transfer to external marketing provider
    Type: String
    */
    allowDataTransfer: null,

    /*
    If the customer allows a delivery of Invoices via electronic
    applications the value is "Electronic invoices".
    Type: String
    */
    allowElectronicInvoices: null
  },

  search: {
    /*
    The entered search keywords for the current search process. If more
    than one keyword was entered please concatenate the keywords. Please
    insert as concatenation symbols the used operators (in English words
    in capital letters).
    Type: string
    Specified values for concatenation:
    AND
    OR
    */
    term: null,

    /*
    The amount of found results
    Type: Integer
    */
    results: null
  },

  menuTracking: {
    /*
    The hierarchy structure in which the page is integrated. This variable
    is an array variable. The values reflect the page hierarchy, not the
    visitor’s path. The information pageName is not part of the
    breadCrumbs. At journeys, pages from Sales Funnel are in the same
    hierarchy level.
    Definition for SPA
    - Scrolling page: Everything on one level
    - Reloading own pages/History Change: If a real hierarchy exists, then
    mapping via array.

    Example from vwfs.de: ["Wertpapiere&Depot", "Wertpapierplan",
    "Aktiendepot"]
    */
    breadCrumbs: []
  },

  /*
  Together with the PO or BA we map our standard events to your journey and
  provide you a specification document with an annotated version of the
  journey. Please fill use the values specified in this document.
  */
  eventInfo: [
    {
      /*
    The type of event which occurred. This information describes the
    context in which the current event occurred. The values are
    specified in the corresponding specification document.
    */
      eventType: null,

      /*
    The event which occurred. The values are specified in the
    corresponding specification document.
    */
      eventAction: null,

      /*
    If a link or button triggers this event, please provide the target
    url of this link.
    */
      eventTargetURL: null,

      /*
    If additional information in context of an interaction element
    is needed. The values are specified in the corresponding
    specification document.
    */

      linkInformation: null
    }
  ],
  event: null
});
