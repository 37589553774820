import React from 'react';
import { Heading, PageWrap, ContentSection, Paragraph, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { REDIRECT_URL } from '../../App';

const ErrorPage: any = () => {
  const { t } = useTranslation();

  return (
    <PageWrap>
      <ContentSection className="u-text-center">
        <Heading level={3}>{t('errorPage:heading')}</Heading>
        <Paragraph
          dangerouslySetInnerHTML={{ __html: t('errorPage:article', { interpolation: { escapeValue: false } }) }}
        ></Paragraph>
        <Button onClick={() => (window.location.href = REDIRECT_URL)}>{t('errorPage:backButton')}</Button>
      </ContentSection>
    </PageWrap>
  );
};

export default ErrorPage;
