import { DeliveryType } from '@bus/models';
import { createSelector } from 'reselect';
import { contactInfoValidation, personalInfoValidation } from '../pages/form-page/validation';
import { RootState } from './types';

export const getSession = (state: RootState) => state.session;
export const getToken = (state: RootState) => state.session?.token;
export const getTransactionStatus = (state: RootState) => state.transactionStatus;
export const getTransactionId = (state: RootState) => state.session?.id;
export const getIsDealershopDelivery = (state: RootState) =>
  state.dataStorage?.data?.financialProduct?.contracts?.[0]?.deliveryData?.deliveryType === DeliveryType.DEALERSHOP ??
  false;
export const getPaymentAuthorizationStatus = (state: RootState) => state.ui.paymentAuthorizationStatus;

export const getLeadFormData = (state: RootState) => state.leadFormData;

export const getPersonalInfo = (state: RootState) => state.personalInfo;
export const getIsPersonalInfoValid = createSelector(getPersonalInfo, personalInfo =>
  personalInfoValidation().isValidSync(personalInfo)
);

export const getContactInfo = (state: RootState) => state.contactInfo;
export const getIsContactInfoValid = createSelector(getContactInfo, contactInfo => {
  return contactInfoValidation().isValidSync(contactInfo);
});

export const getAreFormSectionsValid = createSelector(
  getIsPersonalInfoValid,
  getIsContactInfoValid,
  (...formsValidityArray) => formsValidityArray.every(isValid => Boolean(isValid))
);

export const getIsDataStorageDataLoading = (state: RootState) => state.ui.isDataStorageDataLoading;

export function getIsLogged(state: RootState) {
  return state.keycloak.isLogged;
}
export const getIsTlsSupported = (state: RootState) => state.ui.isTlsSupported;

export const getIsTlsCheckInProgress = (state: RootState) => state.ui.isTlsCheckInProgress;

export const getSummaryConsentsInfo = (state: RootState) => state.summaryConsentsData;

export const getKeycloakCustomerData = (state: RootState) => state.keycloak.customerData;

export const getFirstInstallmentGrossAmount = (state: RootState) =>
  state.dataStorage?.data?.financialProduct?.calculation?.firstInstallmentAmount?.grossAmount || null;

export const getMonthlyTotalRateGrossAmount = (state: RootState) =>
  state.dataStorage?.data?.financialProduct?.calculation?.monthlyTotalRateAmount?.grossAmount || null;
