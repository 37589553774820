import React, { FunctionComponent } from 'react';
import { ElementId, Field } from './model';
import { Input as BronsonInput } from '@vwfs-bronson/bronson-react';

export type InputProps = ElementId & {
  placeholder?: string;
  field: Field;
  /** Formik state and helpers */
  handleOnBlur?: (...vars: any) => void;
  type?: 'text' | 'number' | 'tel' | 'email';
  min?: number;
  max?: number;
  maxLength?: number;
  disabled?: boolean;
};

export const getDescendantProp = (obj: any, path: string) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj);

export const Input: FunctionComponent<InputProps> = ({
  id,
  placeholder,
  field,
  type,
  min,
  max,
  maxLength,
  disabled
}) => {
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    setValue(typeof field.value === 'undefined' ? '' : field.value);
  }, [field.value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    field.onChange(e);
  }

  return (
    <BronsonInput
      type={type || 'text'}
      name={field.name}
      value={value}
      id={id}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={field.onBlur}
      min={min}
      max={max}
      maxLength={maxLength}
      disabled={disabled || false}
    />
  );
};
