import Keycloak from 'keycloak-js';

const scope = 'profile email phone birthdate firstName lastName fullPhoneNumber';

const url = process.env.REACT_APP_IDP_URL || '';

export const isIdentityBrokerEnabled = () =>
  !(
    process.env.REACT_APP_IDENTITY_BROKER_DISABLED &&
    ['true', '1'].includes(process.env.REACT_APP_IDENTITY_BROKER_DISABLED)
  );
export const keycloakBrand = 'vw';

export interface KeycloakClient {
  [key: string]: KeyClockInstance;
}
export interface KeyClockInstance {
  name: string;
  idpHint: string;
  kc: Keycloak;
}

const keycloakConfig = {
  vw: {
    realm: 'vwfs-du-abofr',
    clientId: 'custPortalAbofrVw', // TODO to check
    url,
    scope
  }
};

export const keycloakClient: KeycloakClient = {
  vw: {
    name: 'vwfs',
    idpHint: 'idkabofrvwvw',
    kc: new Keycloak(keycloakConfig.vw)
  }
};
