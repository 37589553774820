import {
  AddressType,
  BrandType,
  CountryCodeType,
  EmailType,
  EntryPointType,
  IdentifierSourceType,
  IdentityDocumentType,
  LinkType,
  PhoneCountryCodeType,
  ProductType
} from '@bus/models';

export type DataStorageObject = {
  id: string;
  product: ProductType;
  country: 'fr';
  entryPoint: EntryPointType;
  brand: BrandType;
  data: DataStorageData;
  creationDate: string;
};

export type DataStorageData = {
  transaction: Transaction;
  salesChannel?: SalesChannel;
  customerData?: CustomerData;
  financialProduct: FinancialProduct;
  vehicleData: VehicleData;
  dealerData: DealerData;
  custom?: CustomData;
};

export type SalesChannel = {
  redirectUrl?: string;
};

export interface ExternalTransactionIdentifier {
  id: string;
  type: IdentifierSourceType.EUROPCAR_PAYMENT_SESSION_ID;
}

export type Transaction = {
  status: TransactionStatus;
  brandRedirectUrl?: string;
  dataPrivacyStatement?: DataPrivacyStatement;
  id: string;
  externalTransactionIdentifiers?: [ExternalTransactionIdentifier];
  links: Link[];
};

export interface Link {
  url: string;
  type: LinkType;
}

export enum TransactionStatus {
  INITIALIZED = 'INITIALIZED',
  LEAD_SUBMITTED = 'LEAD_SUBMITTED',
  APPLICATIONFORM_STARTED = 'APPLICATIONFORM_STARTED',
  APPLICATIONFORM_COMPLETED = 'APPLICATIONFORM_COMPLETED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  CONTRACT_CONFIRMED = 'CONTRACT_CONFIRMED'
}

export type DataPrivacyStatement = {
  dataPrivacyConsentAcceptance?: boolean;
  dataPrivacyConsentAcceptanceDate?: string;
  notAllowPostalTelephoneCommunication?: boolean;
  notAllowPostalTelephoneCommunicationDate?: Date;
  allowMailCommunication?: boolean;
  allowMailCommunicationDate?: string;
  allowMailAds?: boolean;
  allowMailAdsDate?: string;
  allowPhoneAds?: boolean;
  allowPhoneAdsDate?: string;
};

export enum Salutation {
  MR = 'MR',
  MRS = 'MRS',
  OTHER = 'OTHER'
}

export interface DriverLicense {
  identityDocumentType: IdentityDocumentType.DRIVER_LICENSE;
  identityDocumentId: string;
  issuingAuthority: string;
  issuingCountry: CountryCodeType;
  issuedDate: string;
}

export type CustomerData = {
  salutation?: Salutation;
  lastName?: string;
  firstName?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  stateOfBirth?: CountryCodeType;
  maritalStatus?: string;
  identityDocuments: [DriverLicense];
  contactData?: ContactData;
  addresses: [PrimaryAddress, DeliveryAddress?];
  consentData?: ConsentData[];
  isOnSanctionsLists?: boolean;
};

export type ContactData = {
  emails: Email[];
  mobilePhoneCountryCode?: PhoneCountryCodeType;
  mobilePhoneNumber?: string;
};

export type Email = {
  type: EmailType;
  emailAddress: string;
};

export type ConsentData = {
  consentType: LocalConsentType;
  isGiven: boolean;
  date?: string;
  description?: string;
  ipAddress?: string;
};

export type Address<Type> = {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  countryCode: CountryCodeType;
  addressType: Type;
};

export type PrimaryAddress = Address<AddressType.PRIMARY>;
export type DeliveryAddress = Address<AddressType.DELIVERY>;

export type FinancialProduct = {
  calculation?: Calculation;
  additionalServicesDescriptions: string;
  frontendDisplayedLegalNotice?: string;
  contracts: [Contract];
};

export type AdditionalProductCostAmount = {
  currency: string;
  grossAmount: number;
};

export type AdditionalProduct = {
  name: string;
  additionalProductCostAmount: AdditionalProductCostAmount;
  codeFamily: 'DELIVERY' | string;
};

export type Calculation = {
  calcProductId?: string;
  calcProductLabel?: string;
  monthlyTotalRateAmount: MonthlyRateAmount;
  monthlyRateAmount: MonthlyRateAmount;
  monthlyRateAddOnsAmount: MonthlyRateAmount;
  subsequentInstallment: TInstallment;
  monthlyMileage: number;
  duration: number;
  durationUnit: string;
  yearlyMileage?: number;
  mileageUnit?: string;
  downPaymentAmount?: number;
  basePriceAmount: {
    grossAmount: number;
    currency?: string;
  };
  firstInstallmentAmount?: {
    grossAmount: number;
    currency?: string;
  };
  governmentPremiumAmount?: number;
  downPaymentDiscountAmount?: number;
  effectiveDownPaymentAmount?: number;
  additionalProducts: AdditionalProduct[];
  totalOptionsPriceAmount?: TotalOptionsPriceAmount;
  contractMileage?: number;
  nominalInterestPercentage?: number;
  effectiveInterestPercentage?: number;
  staticCosts?: StaticCosts;
  totalOneTimeFeeAmount?: MonthlyRateAmount;
  grossLoanAmount?: GrossLoanAmount;
};

export type GrossLoanAmount = {
  grossAmount: number;
};

export type MonthlyRateAmount = {
  grossAmount: number;
  currency: string;
};

export type StaticCosts = {
  distributionAmount?: DistributionAmount;
};

export type DistributionAmount = {
  grossAmount: number;
  currency: string;
};

export type TotalOptionsPriceAmount = {
  grossAmount: number;
};

export type TInstallment = {
  amount: number;
  dueDate?: string;
};

export type VehicleData = {
  marketingCode: string;
  model: Model;
  residualValueAmount?: ResidualValueAmount;
  vin?: string;
  rppAmount?: number;
  purchasePriceAmount?: number;
  handoverDate?: string;
  campaignDiscountAmount?: number;
  isUsed?: boolean;
  odometer?: number;
  initialRegistrationDate?: string;
  calcProductLabel?: string;
  retailPriceAmount?: RetailPriceAmount;
  delivery?: Contract;
};

export type ResidualValueAmount = {
  grossAmount: number;
};

export interface Driver {
  dateOfBirth: string;
  role: DriverRole;
}

export enum DriverRole {
  DRIVER_ADDITIONAL = 'DRIVER_ADDITIONAL'
}

export type Contract = {
  drivers: Driver[];
  deliveryData: DeliveryCostComponents;
};

export type DeliveryCostComponents = {
  deliveryCostComponents: DeliveryCostAmount[];
  deliveryType: string;
};

export type DeliveryCostAmountField = {
  currency: string;
  grossAmount: number;
};

export type DeliveryCostAmount = {
  costType: string;
  deliveryCostAmount: DeliveryCostAmountField;
};

export type RetailPriceAmount = {
  grossAmount: number;
};

export type VehicleImages = {
  url: string;
};

export type Model = {
  manufacturer: string;
  vehicleImages: VehicleImages[];
  name: string;
  engine: Engine;
  colorExterior: string;
  trimline: string;
  fuelConsumption?: string;
  equipments?: Equipment[];
  descriptionLong?: string;
  description?: string;
  options?: Options[];
};

export type Options = {
  name?: string;
  type?: string;
};

export type Equipment = {
  equipmentId: string;
  equipmentDescription: string;
};

export type Engine = {
  power: number;
  powerUnit?: string;
  name?: string;
  power2nd: string;
  power2ndUnit?: string;
  co2EmissionValue?: string;
  power3rd?: number;
  power3rdUnit?: string;
};

export type DealerData = {
  companyId: string;
  companyIdKVPS: string;
  companyName: string;
  contactData: ContactData;
  addresses?: Address<void>[];
};

export type CustomData = {
  continuationTimestamp?: Date;
  quoteValidity?: {
    date: string;
    dateTime: string;
  };
};

export enum LocalConsentType {
  MARKETING_ADS = 'MARKETING_ADS',
  DATA_PRIVACY = 'DATA_PRIVACY',
  DATA_SHARING_ADS = 'DATA_SHARING_ADS',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  ALLOW_UNENCRYPTED_EMAILS = 'ALLOW_UNENCRYPTED_EMAILS',
  PHONE_ADS = 'PHONE_ADS',
  PERSONALIZED_MARKETING_ADS = 'PERSONALIZED_MARKETING_ADS',
  PHONE_SUPPORT = 'PHONE_SUPPORT'
}
