import { axios } from './apiClient';

export function sendSmsTan({ transactionId }: { transactionId: string }) {
  const url = `/tan/${transactionId}`;
  return axios.post(url).then(res => res.data);
}

export function validateSmsTan({
  transactionId,
  tan
}: {
  transactionId: string;
  tan: string;
}): Promise<{
  tan: string;
  token: string;
}> {
  const url = `/tan/${transactionId}/validity`;
  return axios.post(url, { tan }).then(res => res.data);
}
