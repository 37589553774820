// These must be the first lines.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducer from './redux/reducer';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import * as serviceWorker from './serviceWorker';
import './i18n';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk] as any;

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));
const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

serviceWorker.unregister();
