import applicationPersonInfo from '../common/personalInfo.json';
import applicationContact from '../common/contactInfo.json';
import common from '../common/common.json';
import confirmationPage from '../common/confirmationPage.json';
import errorPage from '../common/errorPage.json';
import landingPage from '../common/landingPage.json';
import summaryPage from '../common/summaryPage.json';
import validation from '../common/validation.json';
import applicationPayment from '../common/payment.json';
import applicationPage from '../common/applicationPage.json';
import contactModal from '../common/contactModal.json';
import mailDispatchModal from '../common/mailDispatchModal.json';
import quoteDispatchModal from '../common/quoteDispatchModal.json';
import paymentPage from '../../../pages/payment-page/paymentPage';

export const i18n = {
  applicationPage,
  applicationContact,
  applicationPersonInfo,
  applicationPayment,
  common,
  confirmationPage,
  errorPage,
  landingPage,
  summaryPage,
  validation,
  contactModal,
  mailDispatchModal,
  quoteDispatchModal,
  paymentPage
};

export default i18n;
