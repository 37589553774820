import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import { Wizard } from '@vwfs-bronson/bronson-react';

const AppWizard: any = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let step = 0;

  if (!pathname.includes(routes.error)) {
    step = 1;
  }
  if (pathname.includes(routes.application.forms)) {
    step = 2;
  }
  if (pathname.includes(routes.application.summary)) {
    step = 3;
  }
  if (pathname.includes(routes.application.payment)) {
    step = 4;
  }
  if (pathname.includes(routes.application.confirmation)) {
    step = 5;
  }

  if (step > 0) {
    const stepStringsArray = [t('Abo-Configuration'), t('Application'), t('Summary'), t('Confirmation')];

    const currentStepString = stepStringsArray[step - 1];
    return (
      <Wizard collapseBreakpoint="s" collapsedText={currentStepString} collapsedCounter={`${step}/5`}>
        <Wizard.Item active={step === 1} done={step > 1}>
          {t('common:wizard.landingPage')}
        </Wizard.Item>
        <Wizard.Item active={step === 2} done={step > 2}>
          {t('common:wizard.applicationPage')}
        </Wizard.Item>
        <Wizard.Item active={step === 3} done={step > 3}>
          {t('common:wizard.summaryPage')}
        </Wizard.Item>
        <Wizard.Item active={step === 4} done={step > 4}>
          {t('common:wizard.payment')}
        </Wizard.Item>
        <Wizard.Item active={step === 5} done={step > 5}>
          {t('common:wizard.confiramtionPage')}
        </Wizard.Item>
      </Wizard>
    );
  }
};

export default AppWizard;
