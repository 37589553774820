import { IdentifierSourceType } from '@bus/models';
import { Button, ButtonContainer, ErrorMessage, Spinner } from '@vwfs-bronson/bronson-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentSession } from '../../../../api/payments';
import { setPaymentAuthorizationStatus } from '../../../../redux/actions';
import { getToken, getTransactionId } from '../../../../redux/selector';
import { PaymentResult } from '../../../../redux/types';
import { UiLogsContext } from '../../../../App';
import { UiLogLevel } from '../../../../models/ui-logs.model';
import { tracking } from '../../../../tracking/tracking';

enum PaymentEventType {
  callbackPlatform = 'callbackPlatform'
}

enum PaymentMsgType {
  iFrameIsLoaded = 'iFrameIsLoaded',
  isFormFilled = 'isFormFilled',
  callbackPayment = 'callbackPayment'
}

interface isFormFilledEventData {
  type: PaymentEventType.callbackPlatform;
  msg: {
    type: PaymentMsgType.isFormFilled;
    payload: {
      status: boolean;
    };
  };
}

interface iFrameIsLoadedEventData {
  type: PaymentEventType.callbackPlatform;
  msg: {
    type: PaymentMsgType.iFrameIsLoaded;
    payload: {
      status: boolean;
      height: number;
    };
  };
}

interface callbackPlatformPaymentError {
  type: PaymentEventType.callbackPlatform;
  msg: {
    paymentStatus: PaymentResult.ERROR;
  };
}

interface callbackPlatformPaymentResult {
  type: PaymentEventType.callbackPlatform;
  msg: {
    type: PaymentMsgType.callbackPayment;
    payload: {
      paymentStatus: PaymentResult;
    };
  };
}

interface MicroformErrorEventData {
  type: PaymentEventType.callbackPlatform;
  msg: {
    error: {
      name: 'MicroformError';
      details: Array<
        | { location: 'number'; message: 'Validation error' }
        | { location: 'securityCode'; message: 'Validation error' }
        | {
            location: 'expirationMonth';
            message: 'Invalid expiry month format';
          }
        | { location: 'expirationYear'; message: 'Invalid expiry year format' }
      >;
    };
  };
}

type IframeEventData =
  | isFormFilledEventData
  | iFrameIsLoadedEventData
  | callbackPlatformPaymentResult
  | callbackPlatformPaymentError
  | MicroformErrorEventData;

export const PaymentModule = (props: any): JSX.Element => {
  const { submitPaymentForm, setIsFormValid } = props;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isIframeLoading, setIsIframeLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [unknownErrorMessage, setUnknownErrorMessage] = React.useState<string | null>(null);

  // Used to reinitialize the iframe in a bit hackish way, by hiding the element and then executing paymentIframe once more
  const [isPaymentIframeHidden, setIsPaymentIframeHidden] = React.useState(false);

  const [paymentExternalIdentifier, setPaymentExternalIdentifier] = React.useState<string | null>(null);

  const [isPaymentFormFilled, setIsPaymentFormFilled] = React.useState(false);

  const paymentTimeout = React.useRef<number | null>();

  const transactionId = useSelector(getTransactionId) as string;
  const token = useSelector(getToken) as string;

  const uiLogsStack = useContext(UiLogsContext);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPaymentAuthorizationStatus(null));
  }, []);

  const reinitializeIframe = () => {
    uiLogsStack.addUiLog(UiLogLevel.info, 'Reinitializing payment iframe...');
    setErrorMessage(null);
    // a bit dirty way to reinitialize the iframe by removing and creating the element again in the DOM
    setIsPaymentIframeHidden(true);
    setIsPaymentIframeHidden(false);
    setPaymentExternalIdentifier(null);
  };

  React.useEffect(() => {
    if (isPaymentFormFilled) {
      setIsFormValid(true);
    }
  }, [isPaymentFormFilled]);

  React.useEffect(() => {
    if (submitPaymentForm) {
      onPaymentContinue();
    }
  }, [submitPaymentForm]);

  const onPaymentContinue = () => {
    setIsLoading(true);
    dispatch(setPaymentAuthorizationStatus(null));
    uiLogsStack.addUiLog(UiLogLevel.info, 'Payment continue button clicked');
    createPaymentSession({ transactionId, token }).then(res => {
      const paymentSessionId =
        res.data.transaction.externalTransactionIdentifiers?.find(
          identifier => identifier.type === IdentifierSourceType.EUROPCAR_PAYMENT_SESSION_ID
        )?.id ?? null;
      uiLogsStack.addUiLog(UiLogLevel.info, `Payment session created with id: ${paymentSessionId}`);
      setPaymentExternalIdentifier(paymentSessionId);
    });
  };

  const iframeMessagesListener = (postMessage: MessageEvent<IframeEventData>) => {
    console.log('IFRAME MESSAGE', postMessage?.data);
    if (postMessage.data.type !== PaymentEventType.callbackPlatform) {
      return;
    }
    if ((postMessage.data as iFrameIsLoadedEventData)?.msg?.type === PaymentMsgType.iFrameIsLoaded) {
      uiLogsStack.addUiLog(UiLogLevel.info, {
        message: `Payment iFrameIsLoaded message received`,
        details: postMessage.data
      });
      if ((postMessage.data as iFrameIsLoadedEventData).msg.payload.status) {
        setIsIframeLoading(false);
      }
    } else if ((postMessage.data as isFormFilledEventData).msg.type === PaymentMsgType.isFormFilled) {
      // handle form being filled in
      const isFormFilled = (postMessage.data as isFormFilledEventData).msg.payload.status;
      uiLogsStack.addUiLog(UiLogLevel.info, {
        message: `Payment isFormFilled message received with value: ${isFormFilled}`
      });
      setIsPaymentFormFilled(isFormFilled);
    } else if ((postMessage.data as callbackPlatformPaymentError).msg?.paymentStatus === PaymentResult.ERROR) {
      // handle payment authorization error
      const paymentStatus = (postMessage.data as callbackPlatformPaymentError).msg.paymentStatus;
      uiLogsStack.addUiLog(UiLogLevel.error, {
        message: 'Payment iframe ERROR result received',
        details: postMessage.data
      });
      setIsLoading(false);
      setUnknownErrorMessage(t('applicationPayment:errorMessages.genericError'));
      removePaymentTimeout();
      dispatch(setPaymentAuthorizationStatus(paymentStatus));
    } else if ((postMessage.data as callbackPlatformPaymentResult).msg.type === PaymentMsgType.callbackPayment) {
      // handle payment authorization result
      setErrorMessage(null);
      setUnknownErrorMessage(null);
      const paymentStatus = (postMessage.data as callbackPlatformPaymentResult).msg.payload.paymentStatus;
      const logLevel = {
        [PaymentResult.AUTHORIZED]: UiLogLevel.info,
        [PaymentResult.REFUSED]: UiLogLevel.warn,
        [PaymentResult.ERROR]: UiLogLevel.error,
        [PaymentResult.CHALLENGED]: UiLogLevel.info,
        [PaymentResult.CHALLENGE_CANCELLED]: UiLogLevel.warn
      }[paymentStatus];
      if (paymentStatus === PaymentResult.AUTHORIZED) {
        tracking.trackEvent({
          event: 'interaction',
          eventInfo: [
            { linkInformation: 'Payment data provided', eventType: 'Payment data', eventAction: 'Provided' },
            { eventType: 'Finance approval', eventAction: 'Request' }
          ]
        });
      }
      if ([PaymentResult.AUTHORIZED, PaymentResult.REFUSED, PaymentResult.ERROR].includes(paymentStatus)) {
        uiLogsStack.addUiLog(logLevel, {
          message: 'Payment status received from iframe',
          details: postMessage.data
        });
        removePaymentTimeout();
        setIsLoading(false);
        dispatch(setPaymentAuthorizationStatus(paymentStatus));
      } else if (paymentStatus === PaymentResult.CHALLENGED) {
        uiLogsStack.addUiLog(UiLogLevel.info, {
          message: 'CHALLENGED payment status received. Challenge modal sholud be displayed.',
          details: postMessage.data
        });
        removePaymentTimeout();
      } else if (paymentStatus === PaymentResult.CHALLENGE_CANCELLED) {
        uiLogsStack.addUiLog(UiLogLevel.info, {
          message: 'CHALLENGE_CANCELLED payment status received. Challenge modal should be closed',
          details: postMessage.data
        });
        removePaymentTimeout();
        setIsLoading(false);
      } else {
        uiLogsStack.addUiLog(UiLogLevel.warn, {
          message: `Unknown payment status ${paymentStatus} received from iframe`,
          details: postMessage.data
        });
        setUnknownErrorMessage(t('applicationPayment:errorMessages.genericError'));
        setIsLoading(false);
        reinitializeIframe();
      }
    } else {
      if ((postMessage.data?.msg as any)?.type !== 'formIsTouched') {
        uiLogsStack.addUiLog(UiLogLevel.info, {
          message: 'Other payment iframe message received',
          details: postMessage.data
        });
      }
    }

    // handle error cases
    const error = (postMessage.data as MicroformErrorEventData).msg?.error;
    if (error?.details?.length) {
      setIsLoading(false);
      uiLogsStack.addUiLog(UiLogLevel.warn, {
        message: 'Potentially expected payment iframe error received',
        details: postMessage.data
      });
      if (error.details[0].location === 'expirationMonth') {
        setErrorMessage(t('applicationPayment:errorMessages.formErrors.expirationMonth'));
      } else if (error.details[0].location === 'expirationYear') {
        setErrorMessage(t('applicationPayment:errorMessages.formErrors.expirationYear'));
      } else if (error.details[0].location === 'number') {
        setErrorMessage(t('applicationPayment:errorMessages.formErrors.number'));
      } else if (error.details[0].location === 'securityCode') {
        setErrorMessage(t('applicationPayment:errorMessages.formErrors.securityCode'));
      } else {
        setErrorMessage(t('applicationPayment:errorMessages.formErrors.formError'));
      }
      uiLogsStack.addUiLog(UiLogLevel.info, {
        message: 'Resetting iframe timeout due to form error',
        details: postMessage.data
      });
      removePaymentTimeout();
    } else if (error) {
      uiLogsStack.addUiLog(UiLogLevel.error, {
        message: 'Unknown payment iframe error received',
        details: postMessage.data
      });
      setUnknownErrorMessage(t('applicationPayment:errorMessages.genericError'));
      setIsLoading(false);
      removePaymentTimeout();
      reinitializeIframe();
    }
  };

  React.useEffect(() => {
    uiLogsStack.addUiLog(UiLogLevel.info, 'Payment accordion initialized');

    return () => {
      removePaymentTimeout();
      uiLogsStack.flushUiLogs();
    };
  }, []);

  const removePaymentTimeout = () => {
    if (paymentTimeout.current) {
      clearTimeout(paymentTimeout.current);
    }
    paymentTimeout.current = null;
  };

  const setNewPaymentTimeout = () => {
    const timeout = 80_000;
    paymentTimeout.current = setTimeout(() => {
      uiLogsStack.addUiLog(UiLogLevel.error, `Payment timeout reached. No payment result received for ${timeout}ms`);
      setIsLoading(false);
      setUnknownErrorMessage(t('applicationPayment:errorMessages.genericError'));
      reinitializeIframe();
    }, timeout) as any;
  };

  React.useEffect(() => {
    if (paymentExternalIdentifier) {
      console.log('EXECUTING payNowIframe with session ID:', paymentExternalIdentifier);
      uiLogsStack.addUiLog(UiLogLevel.info, `Executing payNowIframe with sessionId: ${paymentExternalIdentifier}...`);

      try {
        (window as any).payNowIframe(paymentExternalIdentifier);
      } catch (e) {
        uiLogsStack.addUiLog(UiLogLevel.error, `Error while executing payNowIframe: ${e}`);
        throw e;
      }

      setIsLoading(true);
      setErrorMessage(null);

      removePaymentTimeout();
      setNewPaymentTimeout();
    } else {
      setIsIframeLoading(true);
      setTimeout(() => {
        uiLogsStack.addUiLog(UiLogLevel.info, `Executing paymentIframe...`);
        try {
          (window as any).paymentIframe(
            'fr_FR',
            'desktop' /* styling */,
            'desktop' /* callback - legacy field */,
            location.origin
          );
        } catch (e) {
          uiLogsStack.addUiLog(UiLogLevel.error, `Error while executing paymentIframe: ${e}`);
          throw e;
        }
      }, 1_000);
    }
    window.addEventListener('message', iframeMessagesListener);

    return () => {
      window.removeEventListener('message', iframeMessagesListener);
    };
  }, [paymentExternalIdentifier]);

  return (
    <>
      {isLoading ? <Spinner fullPage /> : <></>}

      {/*eslint-disable-next-line react/no-unknown-property*/}
      {isPaymentIframeHidden ? (
        <></>
      ) : (
        <>
          <div id="pay-form" {...{ 'no-sentry': 'true' }} className={isIframeLoading ? 'hidden' : ''}></div>
          <div id="challenge"></div>
        </>
      )}
      {isIframeLoading ? <Spinner center /> : <></>}
      {(errorMessage || unknownErrorMessage) && <ErrorMessage>{errorMessage || unknownErrorMessage}</ErrorMessage>}
    </>
  );
};
