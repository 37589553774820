import React from 'react';
import {
  Fieldset,
  ContentSection,
  Paragraph,
  Heading,
  Checkbox,
  Layout,
  FormField,
  ButtonContainer,
  Button,
  ErrorMessage as FieldError
} from '@vwfs-bronson/bronson-react';
import { Form, ErrorMessage, useFormikContext, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { ConsentInfo } from '../../../../redux/types';
import { LocalConsentType } from '../../../../models/data-storage.model';

export const SummaryConsentsForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, setFieldValue, isValid }: FormikProps<ConsentInfo> = useFormikContext();

  const personalizedAdsConsentIndex = values.consentData.findIndex(
    consent => consent.consentType === LocalConsentType.MARKETING_ADS
  );
  const phoneAdsConsentIndex = values.consentData.findIndex(
    consent => consent.consentType === LocalConsentType.PHONE_ADS
  );
  const dataSharingConsentIndex = values.consentData.findIndex(
    consent => consent.consentType === LocalConsentType.DATA_SHARING_ADS
  );

  const termsAndConditionsConsentIndex = values.consentData.findIndex(
    consent => consent.consentType === LocalConsentType.TERMS_AND_CONDITIONS
  );

  return (
    <Form>
      <ContentSection.ComponentWrapper>
        <ContentSection>
          <Heading level={5}>{t('summaryPage:privacyInformation.headlineText')}</Heading>
          <Paragraph>
            <span
              role="presentation"
              dangerouslySetInnerHTML={{ __html: t('summaryPage:privacyInformation.subHeaderText') as string }}
            />
          </Paragraph>
          <Paragraph>{t('summaryPage:privacyInformation.text')}</Paragraph>
        </ContentSection>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/1" s="1/1">
              <FormField type="checkbox" htmlFor="personalizedAdsConsent">
                <Checkbox
                  name={`consentData[${personalizedAdsConsentIndex}].isGiven`}
                  checked={values.consentData[personalizedAdsConsentIndex].isGiven}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(`consentData[${personalizedAdsConsentIndex}].isGiven`, e.target.checked)
                  }
                >
                  {t('summaryPage:marketingConsent:vwGroupCheckbox')}
                </Checkbox>
              </FormField>
            </Layout.Item>
            <Layout.Item default="1/1" s="1/1">
              <FormField type="checkbox" htmlFor="phoneAdsConsent">
                <Checkbox
                  name={`consentData[${phoneAdsConsentIndex}].isGiven`}
                  checked={values.consentData[phoneAdsConsentIndex].isGiven}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(`consentData[${phoneAdsConsentIndex}].isGiven`, e.target.checked)
                  }
                >
                  {t('summaryPage:marketingConsent:vwBankCheckbox')}
                </Checkbox>
              </FormField>
            </Layout.Item>
            <Layout.Item default="1/1" s="1/1">
              <FormField type="checkbox" htmlFor="partnersDataSharingConsent">
                <Checkbox
                  name={`consentData[${dataSharingConsentIndex}].isGiven`}
                  checked={values.consentData[dataSharingConsentIndex].isGiven}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(`consentData[${dataSharingConsentIndex}].isGiven`, e.target.checked)
                  }
                >
                  {t('summaryPage:marketingConsent:partnersCheckbox')}
                </Checkbox>
              </FormField>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Paragraph className="u-mt-large">
          {t('summaryPage:marketingConsent.revokeConsentInfo1')}
          <br />
          {t('summaryPage:marketingConsent.revokeConsentInfo2')}
        </Paragraph>
      </ContentSection.ComponentWrapper>
      <ContentSection.ComponentWrapper>
        <ContentSection>
          <Heading level={5}>{t('summaryPage:generalRentalConditions.headlineText')}</Heading>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1">
                <FormField type="checkbox" id="termsAndConditionsConsent">
                  <Checkbox
                    name={`consentData[${termsAndConditionsConsentIndex}].isGiven`}
                    checked={values.consentData[termsAndConditionsConsentIndex].isGiven}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(`consentData[${termsAndConditionsConsentIndex}].isGiven`, e.target.checked)
                    }
                  >
                    <Paragraph>
                      <span
                        role="presentation"
                        dangerouslySetInnerHTML={{ __html: t('summaryPage:generalRentalConditions.text') as string }}
                      />
                    </Paragraph>
                  </Checkbox>
                  <ErrorMessage
                    name={`consentData[${termsAndConditionsConsentIndex}].isGiven`}
                    render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                  />
                </FormField>
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </ContentSection>
      </ContentSection.ComponentWrapper>
    </Form>
  );
};
