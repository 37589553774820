import { EmailType } from '@bus/models';
import {
  getContactInfoFromDataStorageData,
  getLeadDataFromDataStorageData,
  getPersonalInfoFromDataStorageData,
  getSummaryConsentsInfoFromDataStorageData
} from '../pages/landing-page/initialValues';
import actionTypes from './actionTypes';
import { RootState, Session } from './types';

export const SESSION_CACHE_KEY = 'abofr/session';

export const getSessionInitialState = (): Session | null => {
  const isNew = window.location.search.includes('id=');
  if (isNew) {
    sessionStorage.removeItem(SESSION_CACHE_KEY);
  }
  const sessionJSON = sessionStorage.getItem(SESSION_CACHE_KEY) as string;
  return isNew || !sessionJSON ? null : (JSON.parse(sessionJSON) as Session);
};

export const initialState: RootState = {
  dataStorage: null,

  leadFormData: getLeadDataFromDataStorageData(),
  personalInfo: getPersonalInfoFromDataStorageData(),
  contactInfo: getContactInfoFromDataStorageData(),
  summaryConsentsData: getSummaryConsentsInfoFromDataStorageData(),

  appError: null,
  session: getSessionInitialState(),
  transactionStatus: null,
  ui: {
    isDataStorageDataLoading: false,
    isDataStorageDataLoaded: false,
    isTlsSupported: true,
    isTlsCheckInProgress: false,
    paymentAuthorizationStatus: null
  },
  keycloak: {
    customerData: {},
    idp: '',
    idpHint: '',
    isLogged: false,
    trackAuthSuccess: false
  }
};

export default (state = initialState, action: any): RootState => {
  const payload = action.payload?.body ? JSON.parse(action.payload.body) : action.payload;
  switch (action.type) {
    case actionTypes.SET_APP_ERROR:
      return {
        ...state,
        appError: payload,
        [action.error]: true
      };
    case actionTypes.SET_SESSION:
      return {
        ...state,
        session: payload
      };
    case actionTypes.SET_DATA_STORAGE:
      return {
        ...state,
        dataStorage: payload,
        transactionStatus: payload.data.transaction.status
      };
    case actionTypes.SET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: payload
      };
    case actionTypes.SET_LEAD_FORM_DATA:
      return {
        ...state,
        leadFormData: payload,
        personalInfo: {
          ...state.personalInfo,
          firstName: payload.firstName,
          lastName: payload.lastName
        },
        contactInfo: {
          ...state.contactInfo,
          contactData: {
            ...state.contactInfo.contactData,
            mobilePhoneNumber: payload.contactData.mobilePhoneNumber,
            mobilePhoneCountryCode: payload.contactData.mobilePhoneCountryCode,
            emails: [
              {
                type: EmailType.PERSONAL_PRIMARY,
                emailAddress: payload.contactData.emails[0].emailAddress
              }
            ]
          }
        }
      };
    case actionTypes.SET_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: payload,
        leadFormData: {
          ...state.leadFormData,
          firstName: payload.firstName,
          lastName: payload.lastName
        }
      };
    case actionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: payload,
        leadFormData: {
          ...state.leadFormData,
          contactData: {
            ...state.leadFormData.contactData,
            mobilePhoneNumber: payload.contactData.mobilePhoneNumber,
            mobilePhoneCountryCode: payload.contactData.mobilePhoneCountryCode,
            emails: [
              {
                ...payload.contactData.emails[0],
                emailAddress: payload.contactData.emails[0].emailAddress
              }
            ]
          }
        }
      };
    case actionTypes.SET_DATA_STORAGE_DATA_IS_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDataStorageDataLoading: payload
        }
      };
    case actionTypes.SET_PAYMENT_AUTHORIZATION_STATUS:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentAuthorizationStatus: payload
        }
      };
    case actionTypes.SET_IS_TLS_SUPPORTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTlsSupported: payload
        }
      };
    case actionTypes.SET_IS_TLS_CHECK_IN_PROGRESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTlsCheckInProgress: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_DATA:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          customerData: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_IDP:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          idp: payload
        }
      };
    case actionTypes.SET_KEYCLOAK_IDP_HINT:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          idpHint: payload
        }
      };
    case actionTypes.SET_IS_LOGGED:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          isLogged: payload
        }
      };
    case actionTypes.SET_TRACK_AUTH_SUCCESS:
      return {
        ...state,
        keycloak: {
          ...state.keycloak,
          trackAuthSuccess: payload
        }
      };
    default:
      return state;
  }
};
