import { axios } from './apiClient';
import { DataStorageObject } from '../models/data-storage.model';

export type CreateOrderRequest = {
  transactionId: string;
  token: string;
};

export const createOrderTransaction = async ({
  transactionId,
  token
}: CreateOrderRequest): Promise<DataStorageObject> => {
  const response = await axios.post(`/orders/${transactionId}`, {}, { headers: { Authorization: token } });

  return response.data;
};
