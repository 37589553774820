import React from 'react';
import { AddressType } from '@bus/models';
import { Button, ButtonContainer, ContentSection, Heading, Toggle, Spinner } from '@vwfs-bronson/bronson-react';
import { Form, FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsDealershopDelivery, getIsTlsCheckInProgress } from '../../../../redux/selector';
import { ContactInfo } from '../../../../redux/types';
import { getEmptyAddressValue } from '../../../landing-page/initialValues';
import { AddressFieldset } from './AddressFieldset';

const findDeliveryAddressIndex = (addresses: ContactInfo['addresses']): number =>
  addresses.findIndex(address => address?.addressType === AddressType.DELIVERY);

export const ContactInfoForm = (): JSX.Element => {
  const formikProps: FormikProps<ContactInfo> = useFormikContext();
  const { t } = useTranslation();
  const isTlsCheckInProgress = useSelector(getIsTlsCheckInProgress);

  const [isDeliveryAddressSelected, setIsDeliveryAddressSelected] = React.useState(
    findDeliveryAddressIndex(formikProps.values.addresses) !== -1
  );

  const [deliveryAddressIndex, setDeliveryAddressIndex] = React.useState<number>(
    findDeliveryAddressIndex(formikProps.values.addresses)
  );

  const isDealershopDelivery = useSelector(getIsDealershopDelivery);

  React.useEffect(() => {
    const deliveryIndex = findDeliveryAddressIndex(formikProps.values.addresses);

    setDeliveryAddressIndex(deliveryIndex);
    setIsDeliveryAddressSelected(deliveryIndex > -1);
  }, [formikProps.values.addresses]);

  const handleDeliveryToggle = () => {
    const isSelected = !isDeliveryAddressSelected;
    if (isSelected) {
      formikProps.setFieldValue(`addresses`, [
        ...formikProps.values.addresses,
        getEmptyAddressValue(AddressType.DELIVERY)
      ]);
    } else {
      formikProps.setFieldValue(
        `addresses`,
        formikProps.values.addresses.filter(address => address?.addressType !== AddressType.DELIVERY)
      );
    }
    setIsDeliveryAddressSelected(isSelected);
  };

  return (
    <Form>
      <AddressFieldset addressNamePrefix="addresses[0]." contactNamePrefix="contactData." withContactData={true} />

      {!isDealershopDelivery && (
        <Toggle
          inputType="checkbox"
          onChange={handleDeliveryToggle}
          className="u-mt-xsmall"
          checked={deliveryAddressIndex > -1}
        >
          {t('applicationContact:addDifferentDeliveryAddress')}
        </Toggle>
      )}

      {isDeliveryAddressSelected && (
        <ContentSection>
          <Heading level={5}>{t('applicationContact:differentDeliveryAddress.headerText')}</Heading>
          <AddressFieldset
            addressNamePrefix={`addresses[${deliveryAddressIndex}].`}
            contactNamePrefix=""
            withContactData={false}
          />
        </ContentSection>
      )}

      <ButtonContainer nav>
        {isTlsCheckInProgress ? (
          <Spinner small />
        ) : (
          <Button inContainer type="submit">
            {t('applicationPersonInfo:continueBtn')}
          </Button>
        )}
      </ButtonContainer>
    </Form>
  );
};
