import { axios } from './apiClient';
import { DataStorageData } from '../models/data-storage.model';
import { ConsentInfo } from '../redux/types';

export type FormCompletionRequest = {
  transactionId: string;
  data?: ConsentInfo;
  token: string;
};

export const completeForm = async ({ transactionId, data, token }: FormCompletionRequest): Promise<DataStorageData> => {
  const response = await axios.post<DataStorageData>(`/form-completion/${transactionId}`, data, {
    headers: { Authorization: token }
  });

  return response.data;
};
