import React from 'react';
import { Modal, Layout, ItemTeaser, Paragraph, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLeadFormData } from '../../redux/selector';

interface QuoteModalProps {
  quoteDispatchModalShown: boolean;
  setQuoteDispatchModalShown: (condition: boolean) => void;
  handleResendDocument: () => Promise<void>;
}

export const QuoteDispatchModal = ({
  quoteDispatchModalShown,
  setQuoteDispatchModalShown,
  handleResendDocument
}: QuoteModalProps): JSX.Element => {
  const { t } = useTranslation();
  const leadFormData = useSelector(getLeadFormData);

  return (
    <Modal
      id="quoteDispatchModal"
      onClickOutside={() => {
        setQuoteDispatchModalShown(false);
      }}
      shown={quoteDispatchModalShown}
      title={t('quoteDispatchModal:header')}
      center
      hideCloseButton
      buttonConfirmText={t('quoteDispatchModal:buttonLabel')}
      buttonConfirmType="submit"
      onConfirm={() => {
        setQuoteDispatchModalShown(false);
      }}
    >
      <Layout center>
        <Layout.Item>
          <ItemTeaser center icon="semantic-checkmark">
            <Paragraph>
              {t('quoteDispatchModal:paragraph', { email: leadFormData.contactData.emails[0].emailAddress })}
            </Paragraph>
            <Paragraph>
              <Link onClick={handleResendDocument}>{t('quoteDispatchModal:resendEmail')}</Link>
            </Paragraph>
          </ItemTeaser>
        </Layout.Item>
      </Layout>
    </Modal>
  );
};
