import { axios } from './apiClient';
import { DataStorageObject } from '../models/data-storage.model';
import { ConsentInfo } from '../redux/types';

export type ConfirmationRequest = {
  transactionId: string;
  token: string;
  data: ConsentInfo;
};

export const confirmTransaction = async ({
  transactionId,
  data,
  token
}: ConfirmationRequest): Promise<DataStorageObject> => {
  const response = await axios.post(`/form-confirmations/${transactionId}`, data, {
    headers: { Authorization: token }
  });

  return response.data;
};
