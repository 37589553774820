import { AddressType, CountryCodeType, EmailType, IdentityDocumentType, PhoneCountryCodeType } from '@bus/models';
import moment from 'moment';
import { ContactInfo, LeadFormDataState, PersonalInfo } from '../../redux/types';
import { DataStorageData, LocalConsentType, PrimaryAddress, Salutation } from '../../models/data-storage.model';

export function convertBffDateToUiDate(date: string | undefined): string {
  if (!date) {
    return '';
  }
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}

export const getLeadDataFromDataStorageData = (
  dataStorageData?: DataStorageData,
  isTlsSupported?: boolean
): LeadFormDataState => {
  const customerEmail = dataStorageData?.customerData?.contactData?.emails?.find(
    mail => mail.type === EmailType.PERSONAL_PRIMARY
  );
  const dataPrivacyConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.DATA_PRIVACY
  );
  const phoneSupportConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.PHONE_SUPPORT
  );
  const allowUnencryptedEmailsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
  );

  const consentData = isTlsSupported
    ? [
        { consentType: LocalConsentType.DATA_PRIVACY, isGiven: dataPrivacyConsent?.isGiven ?? false },
        { consentType: LocalConsentType.PHONE_SUPPORT, isGiven: phoneSupportConsent?.isGiven ?? true }
      ]
    : [
        { consentType: LocalConsentType.DATA_PRIVACY, isGiven: dataPrivacyConsent?.isGiven ?? false },
        { consentType: LocalConsentType.PHONE_SUPPORT, isGiven: phoneSupportConsent?.isGiven ?? true },
        {
          consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS,
          isGiven: allowUnencryptedEmailsConsent?.isGiven ?? false
        }
      ];

  return {
    firstName: dataStorageData?.customerData?.firstName ?? '',
    lastName: dataStorageData?.customerData?.lastName ?? '',
    contactData: {
      emails: [
        {
          type: customerEmail?.type ?? EmailType.PERSONAL_PRIMARY,
          emailAddress: customerEmail?.emailAddress ?? ''
        }
      ],
      mobilePhoneCountryCode:
        dataStorageData?.customerData?.contactData?.mobilePhoneCountryCode ?? PhoneCountryCodeType.France,
      mobilePhoneNumber: dataStorageData?.customerData?.contactData?.mobilePhoneNumber ?? ''
    },
    consentData
  };
};

export const getPersonalInfoFromDataStorageData = (dataStorageData?: DataStorageData): PersonalInfo => {
  return {
    salutation: dataStorageData?.customerData?.salutation ?? ('' as Salutation),
    firstName: dataStorageData?.customerData?.firstName ?? '',
    lastName: dataStorageData?.customerData?.lastName ?? '',
    dateOfBirth: convertBffDateToUiDate(dataStorageData?.customerData?.dateOfBirth),
    placeOfBirth: dataStorageData?.customerData?.placeOfBirth ?? '',
    stateOfBirth: dataStorageData?.customerData?.stateOfBirth ?? CountryCodeType.France,
    identityDocuments: dataStorageData?.customerData?.identityDocuments?.length
      ? (dataStorageData.customerData.identityDocuments.map(document => ({
          ...document,
          issuedDate: convertBffDateToUiDate(document.issuedDate)
        })) as any)
      : [
          {
            identityDocumentType: IdentityDocumentType.DRIVER_LICENSE,
            identityDocumentId: '',
            issuingAuthority: '',
            issuingCountry: CountryCodeType.France,
            issuedDate: ''
          }
        ]
  };
};

export function getEmptyAddressValue(addressType: AddressType) {
  return {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    countryCode: CountryCodeType.France,
    addressType: addressType
  };
}

export const getContactInfoFromDataStorageData = (
  dataStorageData?: DataStorageData,
  isTlsSupported?: boolean
): ContactInfo => {
  const contactInfoInitialValues = {
    addresses: (dataStorageData?.customerData?.addresses?.map(address => ({
      ...address,
      houseNumber: address?.houseNumber ?? ''
    })) as any) ?? [getEmptyAddressValue(AddressType.PRIMARY) as PrimaryAddress],
    contactData: {
      emails: dataStorageData?.customerData?.contactData?.emails ?? [
        {
          type: EmailType.PERSONAL_PRIMARY,
          emailAddress: ''
        }
      ],
      mobilePhoneCountryCode:
        dataStorageData?.customerData?.contactData?.mobilePhoneCountryCode ?? PhoneCountryCodeType.France,
      mobilePhoneNumber: dataStorageData?.customerData?.contactData?.mobilePhoneNumber ?? ''
    }
  };

  if (!isTlsSupported) {
    const allowUnencryptedEmailsConsent = dataStorageData?.customerData?.consentData?.find(
      consent => consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
    );

    return {
      ...contactInfoInitialValues,
      consentData: [
        {
          consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS,
          isGiven: allowUnencryptedEmailsConsent?.isGiven ?? false
        }
      ]
    };
  } else {
    return contactInfoInitialValues;
  }
};

export const getSummaryConsentsInfoFromDataStorageData = (dataStorageData?: DataStorageData) => {
  const marketingAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.MARKETING_ADS
  );
  const phoneAdsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.PHONE_ADS
  );
  const dataSharingConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.DATA_SHARING_ADS
  );
  const termsAndConditionsConsent = dataStorageData?.customerData?.consentData?.find(
    consent => consent.consentType === LocalConsentType.TERMS_AND_CONDITIONS
  );

  return {
    consentData: [
      { consentType: LocalConsentType.MARKETING_ADS, isGiven: marketingAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.PHONE_ADS, isGiven: phoneAdsConsent?.isGiven ?? false },
      { consentType: LocalConsentType.DATA_SHARING_ADS, isGiven: dataSharingConsent?.isGiven ?? false },
      { consentType: LocalConsentType.TERMS_AND_CONDITIONS, isGiven: termsAndConditionsConsent?.isGiven ?? false }
    ]
  };
};
