import React from 'react';
import { connect } from 'react-redux';
import { Button, Layout } from '@vwfs-bronson/bronson-react';
import { useKeycloak } from '@react-keycloak/web';

import { useTranslation } from 'react-i18next';
import { getIsLogged } from '../../redux/selector';
import { RootState } from '../../redux/types';

export interface LoginProps {
  isLogged: boolean;
  isLogoutEnabled: boolean;
}

const _HeaderLogout: React.FC<LoginProps> = ({ isLogged, isLogoutEnabled = true }) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const logout = () => {
    return keycloak.logout();
  };

  return (
    <>
      {isLogged && isLogoutEnabled ? (
        <Layout.Item default="1/6" s="1/1" className="o-layout o-layout--right">
          <Button secondary icon="logout" small simple link onClick={logout} type="button">
            {t('landingPage:IdButtonLogout')}
          </Button>
        </Layout.Item>
      ) : (
        ''
      )}
    </>
  );
};

export default connect((state: RootState) => ({
  isLogged: getIsLogged(state)
}))(_HeaderLogout);
