import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@vwfs-bronson/bronson-react';
import { useKeycloak } from '@react-keycloak/web';

import { useTranslation } from 'react-i18next';
import { getIsLogged } from '../../redux/selector';
import { RootState } from '../../redux/types';
import { KeyClockInstance, keycloakClient } from '../../resources/identity-brocker/keycloak';
import { tracking } from '../../tracking/tracking';

export interface LoginProps {
  isLogoutEnabled: boolean;
  isLoginEnabled: boolean;
  isLogged: boolean;
}

const _Login: React.FC<LoginProps> = ({ isLogoutEnabled = true, isLoginEnabled = true, isLogged }) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const oauthValidation = (config: KeyClockInstance) => {
    if (!keycloak.authenticated) {
      keycloak.login({ idpHint: config.idpHint });
    }
  };

  const login = () => {
    tracking.trackEvent({
      event: 'interaction',
      eventInfo: [
        {
          eventType: 'Login',
          eventAction: 'Start',
          linkInformation: 'Login via VW ID'
        }
      ]
    });
    setTimeout(() => {
      oauthValidation(keycloakClient.vw);
    }, 100);
  };
  const logout = () => {
    return keycloak.logout();
  };

  return (
    <>
      {!isLogged && isLoginEnabled ? (
        <Button secondary small onClick={login} type="button">
          {t('landingPage:IdButtonLogin')}
        </Button>
      ) : (
        ''
      )}
      {isLogged && isLogoutEnabled ? (
        <Button secondary small onClick={logout} type="button">
          {t('landingPage:IdButtonLogout')}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

export default connect((state: RootState) => ({
  isLogged: getIsLogged(state)
}))(_Login);
