import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { getDataStorageData } from '../api/data-storage';
import {
  getLeadDataFromDataStorageData,
  getPersonalInfoFromDataStorageData,
  getContactInfoFromDataStorageData,
  getSummaryConsentsInfoFromDataStorageData
} from '../pages/landing-page/initialValues';
import { routeByStatus } from '../routes';
import { DataStorageObject, LocalConsentType, Salutation, TransactionStatus } from '../models/data-storage.model';
import actionTypes from './actionTypes';
import { SESSION_CACHE_KEY } from './reducer';
import { EmailType } from '@bus/models';
import { checkTLS } from '../api/tls-check';
import {
  ConsentInfo,
  ContactInfo,
  KeycloakCustomerData,
  LeadFormDataState,
  PaymentResult,
  PersonalInfo,
  Session
} from './types';
import { tracking } from '../tracking/tracking';

const getPersonalEmailAddress = (storefrontData: DataStorageObject) =>
  storefrontData.data.customerData?.contactData?.emails.find(email => email.type === EmailType.PERSONAL_PRIMARY)
    ?.emailAddress;

export const fetchDataStorageData = (storefrontId: string, token: string, navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    dispatch(setDataStorageDataLoading(true));
    try {
      const storefrontData = await getDataStorageData({
        transactionId: storefrontId,
        token
      });
      dispatch(setDataStorage(storefrontData));

      tracking.extendTrackingDataWithStorefront(storefrontData);

      const personalEmailAddress = getPersonalEmailAddress(storefrontData);
      let isTlsSupportedCheckResult = true;

      const personalInfo = getPersonalInfoFromDataStorageData(storefrontData.data);

      dispatch(setPersonalInfo(personalInfo));
      const summaryConsentsInfo = getSummaryConsentsInfoFromDataStorageData(storefrontData.data);
      dispatch(setSummaryConsentsInfo(summaryConsentsInfo));

      if (personalEmailAddress) {
        dispatch(setIsTlsCheckInProgress(true));
        isTlsSupportedCheckResult = await checkTLS({
          email: personalEmailAddress,
          transactionId: storefrontId,
          token
        })
          .then(res => {
            dispatch(setTlsIsSupported(res.tlsSupported));
            return res.tlsSupported;
          })
          .catch(() => false)
          .finally(() => dispatch(setIsTlsCheckInProgress(false)));
      }

      const leadFormData = getLeadDataFromDataStorageData(storefrontData.data, isTlsSupportedCheckResult);
      dispatch(setLeadData(leadFormData));
      const contactInfo = getContactInfoFromDataStorageData(storefrontData.data, isTlsSupportedCheckResult);
      dispatch(setContactInfo(contactInfo));

      const hash = window.location.hash.substring(1);
      navigate(`${routeByStatus(storefrontData.data.transaction.status)}${hash ? '#' + hash : ''}`, {});
    } catch (error) {
      dispatch({
        type: actionTypes.SET_APP_ERROR,
        payload: error
      });
    } finally {
      dispatch(setDataStorageDataLoading(false));
    }
  };
};

export const addSessionDataToRedux = (storefrontId: string, token: string | null) => {
  return (dispatch: Dispatch) => {
    dispatch(setSession({ token: token, id: storefrontId }));
  };
};

export const setSession = (data: Session) => {
  sessionStorage.setItem(SESSION_CACHE_KEY, JSON.stringify(data));
  return {
    type: actionTypes.SET_SESSION,
    payload: data
  };
};

export const setDataStorageDataLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_DATA_STORAGE_DATA_IS_LOADING,
  payload: isLoading
});

export const setDataStorage = (payload: DataStorageObject) => ({
  type: actionTypes.SET_DATA_STORAGE,
  payload: payload
});

export const setLeadData = (payload: LeadFormDataState) => {
  return {
    type: actionTypes.SET_LEAD_FORM_DATA,
    payload
  };
};

export const setPersonalInfo = (payload: PersonalInfo) => {
  const salutationToGender = {
    [Salutation.MR]: 'M',
    [Salutation.MRS]: 'F',
    [Salutation.OTHER]: null
  };
  tracking.extendTrackingData({
    customerData: {
      gender: salutationToGender[payload.salutation] ?? null,
      yearOfBirth: payload.dateOfBirth?.split('/')?.[2] ?? null
    }
  });
  return {
    type: actionTypes.SET_PERSONAL_INFO,
    payload
  };
};

export const setContactInfo = (payload: ContactInfo) => {
  tracking.extendTrackingData({
    customerData: {
      address: {
        zipCode: payload.addresses[0]?.zipCode ?? null
      }
    }
  });
  return {
    type: actionTypes.SET_CONTACT_INFO,
    payload
  };
};

export function setKeycloakData(data: KeycloakCustomerData) {
  return {
    type: actionTypes.SET_KEYCLOAK_DATA,
    payload: data
  };
}

export function setKeycloakIdp(idp: string) {
  return {
    type: actionTypes.SET_KEYCLOAK_IDP,
    payload: idp
  };
}

export function setKeycloakIdpHint(idpHint: string) {
  return {
    type: actionTypes.SET_KEYCLOAK_IDP_HINT,
    payload: idpHint
  };
}

export function setIsLogged(isLogged: boolean) {
  return {
    type: actionTypes.SET_IS_LOGGED,
    payload: isLogged
  };
}

export function setTrackAuthSuccess(trackAuthSuccess: boolean) {
  return {
    type: actionTypes.SET_TRACK_AUTH_SUCCESS,
    payload: trackAuthSuccess
  };
}

export function setTransactionStatus(transactionStatus: TransactionStatus) {
  return {
    type: actionTypes.SET_TRANSACTION_STATUS,
    payload: transactionStatus
  };
}

export const setPaymentAuthorizationStatus = (payload: PaymentResult | null) => ({
  type: actionTypes.SET_PAYMENT_AUTHORIZATION_STATUS,
  payload
});

export const setSummaryConsentsInfo = (payload: ConsentInfo) => {
  const isMarketingConsentGiven = payload.consentData.find(
    consent => consent.consentType === LocalConsentType.MARKETING_ADS
  )?.isGiven;

  tracking.extendTrackingData({
    dataPrivacyStatement: {
      allowPostalAds: isMarketingConsentGiven,
      allowPhoneAds: isMarketingConsentGiven,
      allowElectronicAds: isMarketingConsentGiven,
      allowMailAds: isMarketingConsentGiven
    }
  });

  return {
    type: actionTypes.SET_CONSENTS_INFO,
    payload
  };
};

export const setTlsIsSupported = (payload: boolean) => ({
  type: actionTypes.SET_IS_TLS_SUPPORTED,
  payload
});

export const setIsTlsCheckInProgress = (payload: boolean) => ({
  type: actionTypes.SET_IS_TLS_CHECK_IN_PROGRESS,
  payload
});
