import { EmailType, PhoneCountryCodeType } from '@bus/models';
import {
  Button,
  ButtonContainer,
  Checkbox,
  ContentSection,
  ErrorMessage as FieldError,
  Fieldset,
  FormField,
  Heading,
  InfoIcon,
  Layout,
  Paragraph,
  Select,
  Spinner,
  UnorderedList
} from '@vwfs-bronson/bronson-react';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveLeadData } from '../../../api/lead-data';
import { checkTLS } from '../../../api/tls-check';
import { Input } from '../../../components/Input/Input';
import { setDataStorage, setIsTlsCheckInProgress, setLeadData, setTlsIsSupported } from '../../../redux/actions';
import {
  getIsTlsCheckInProgress,
  getIsTlsSupported,
  getKeycloakCustomerData,
  getLeadFormData,
  getSession
} from '../../../redux/selector';
import { LeadFormDataState } from '../../../redux/types';
import { routes } from '../../../routes';
import { getLeadDataFromDataStorageData } from '../initialValues';
import { emailAddressValidator, leadDataDetailsValidation } from '../validation';
import { LocalConsentType } from '../../../models/data-storage.model';

interface Props {
  afterSubmitCb?: () => any;
}

export const LeadDataDetailsForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formikRef = React.useRef<FormikProps<LeadFormDataState>>(null);

  const { afterSubmitCb } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const isTlsSupported = useSelector(getIsTlsSupported);
  const isTlsInProgress = useSelector(getIsTlsCheckInProgress);
  const session = useSelector(getSession);

  const dispatch = useDispatch();

  const leadFormData = useSelector(getLeadFormData);
  const keycloakCustomerData = useSelector(getKeycloakCustomerData);
  const [formValues, setFormValues] = React.useState<LeadFormDataState>(leadFormData);

  const checkIfEmailIsValid = (value: string) => {
    return emailAddressValidator.isValidSync(value);
  };

  React.useEffect(() => {
    setFormValues({
      ...leadFormData,
      firstName: leadFormData.firstName || keycloakCustomerData?.firstName || '',
      lastName: leadFormData.lastName || keycloakCustomerData?.lastName || '',
      contactData: {
        ...leadFormData.contactData,
        emails: [
          {
            type: EmailType.PERSONAL_PRIMARY,
            emailAddress:
              leadFormData.contactData?.emails[0]?.emailAddress || keycloakCustomerData?.contactData?.email || ''
          }
        ]
      }
    });
  }, [leadFormData, keycloakCustomerData]);

  const performTlsCheck = (email: string) => {
    dispatch(setIsTlsCheckInProgress(true));
    checkTLS({ email, transactionId: session?.id as string, token: session?.token as string })
      .then(res => {
        const { tlsSupported } = res;
        dispatch(setTlsIsSupported(tlsSupported));

        if (tlsSupported) {
          formikRef.current?.setFieldValue(
            'consentData',
            leadFormData.consentData.filter(
              consent => consent.consentType !== LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
            )
          );
        } else {
          formikRef.current?.setFieldValue('consentData[2]', {
            isGiven: res.tlsSupported,
            consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
          });
        }
      })
      .catch(() => {
        dispatch(setTlsIsSupported(false));
        formikRef.current?.setFieldValue('consentData[2]', {
          isGiven: false,
          consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
        });
      })
      .finally(() => {
        dispatch(setIsTlsCheckInProgress(false));
      });
  };

  const debouncedTlsCheck = debounce(performTlsCheck, 1000);

  const addAdditionalConsentValues = (values: LeadFormDataState): LeadFormDataState => {
    values.consentData.forEach(consent => {
      if (consent.consentType === LocalConsentType.DATA_PRIVACY) {
        consent.description = t('landingPage:leadForm.consentData.dataPrivacyWithoutHTML') as string;
      }
      if (consent.consentType === LocalConsentType.PHONE_SUPPORT) {
        consent.description = t('landingPage:leadForm.consentData.phoneSupport') as string;
      }
      if (consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS) {
        consent.description = t('common:tlsCheckContent:checkboxContent') as string;
      }
    });

    return values;
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={formValues}
        validationSchema={leadDataDetailsValidation(isTlsSupported)}
        onSubmit={values => {
          if (!session) {
            return;
          }
          setIsLoading(true);
          const valuesUpdated = addAdditionalConsentValues(values);

          saveLeadData({
            transactionId: session.id,
            token: session.token as string,
            data: valuesUpdated
          })
            .then(res => {
              dispatch(setLeadData(getLeadDataFromDataStorageData(res.data, isTlsSupported)));
              dispatch(setDataStorage(res));
              if (afterSubmitCb) {
                afterSubmitCb();
              }
              navigate(routes.application.forms);
              window.scrollTo(0, 0);
            })
            .catch(() => navigate(routes.error))
            .finally(() => setIsLoading(false));
        }}
      >
        {(formProps: FormikProps<LeadFormDataState>) => {
          return (
            <Form>
              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      notion
                      type="input"
                      htmlFor="leadFirstName"
                      labelText={t('landingPage:leadForm.firstName')}
                    >
                      <Field component={Input} name="firstName" id="leadFirstName" maxLength="80" />
                      <ErrorMessage name="firstName" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
                    </FormField>
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      notion
                      type="input"
                      htmlFor="leadLastName"
                      labelText={t('landingPage:leadForm.lastName')}
                    >
                      <Field component={Input} name="lastName" id="leadLastName" maxLength="80" />
                      <ErrorMessage name="lastName" render={(msg: string) => <FieldError>{t(msg)}</FieldError>} />
                    </FormField>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>
              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      notion
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        debouncedTlsCheck.cancel();
                        const isEmailValid = checkIfEmailIsValid(e.target.value);
                        if (isEmailValid && e.target.value) {
                          debouncedTlsCheck(e.target.value);
                        }
                      }}
                      type="input"
                      htmlFor="leadEmailAddress"
                      labelText={t('landingPage:leadForm.contactData.emailAddress')}
                    >
                      <Field component={Input} name="contactData.emails[0].emailAddress" maxLength="80" />
                      <ErrorMessage
                        name="contactData.emails[0].emailAddress"
                        render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                      />
                    </FormField>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>
              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      notion
                      type="select"
                      htmlFor="leadMobilePhoneCountryCode"
                      labelText={t('landingPage:leadForm.contactData.countryCode')}
                    >
                      <Field as={Select} name="contactData.mobilePhoneCountryCode">
                        {Object.entries(PhoneCountryCodeType).map(([countryLabel, countryCode]) => (
                          <Select.Item key={`${countryCode}${countryLabel}`} value={countryCode}>
                            {countryCode} {countryLabel.replace(/([A-Z])/g, ' $1').trim()}
                          </Select.Item>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="contactData.mobilePhoneCountryCode"
                        render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                      />
                    </FormField>
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      infoIcon={
                        <InfoIcon icon="semantic-info">{t('landingPage:leadForm.mobilePhoneNumberTooltip')}</InfoIcon>
                      }
                      notion
                      type="input"
                      htmlFor="leadMobilePhoneNumber"
                      labelText={t('landingPage:leadForm.contactData.mobilePhoneNumber')}
                    >
                      <Field component={Input} name="contactData.mobilePhoneNumber" maxLength="80" />
                      <ErrorMessage
                        name="contactData.mobilePhoneNumber"
                        render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                      />
                    </FormField>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>
              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/1" s="1/1">
                    <FormField notion type="checkbox" htmlFor="leadDataPrivacyConsent">
                      <Checkbox
                        name="consentData[0].isGiven"
                        checked={formProps.values?.consentData[0]?.isGiven}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formProps.setFieldValue('consentData[0].isGiven', e.target.checked)
                        }
                      >
                        <span
                          role="presentation"
                          dangerouslySetInnerHTML={{
                            __html: t('landingPage:leadForm.consentData.dataPrivacy') as string
                          }}
                        />
                      </Checkbox>
                      <ErrorMessage
                        name="consentData[0].isGiven"
                        render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                      />
                    </FormField>
                  </Layout.Item>
                  <Layout.Item default="1/1" s="1/1">
                    <FormField type="checkbox" htmlFor="leadPhoneSupportConsent">
                      <Checkbox
                        name="consentData[1].isGiven"
                        checked={!formProps.values.consentData[1]?.isGiven}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          formProps.setFieldValue('consentData[1].isGiven', !e.target.checked)
                        }
                      >
                        {t('landingPage:leadForm.consentData.phoneSupport')}
                      </Checkbox>
                      <ErrorMessage
                        name="consentData[1].isGiven"
                        render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                      />
                    </FormField>
                  </Layout.Item>
                  {!isTlsSupported && (
                    <ContentSection>
                      <Layout.Item default="1/1" s="1/1">
                        <Heading level={5}>{t('common:tlsCheckContent:heading')}</Heading>
                        <Paragraph>{t('common:tlsCheckContent:contentTxt')}</Paragraph>
                        <UnorderedList>
                          <UnorderedList.Item>{t('common:tlsCheckContent:contentPointFirst')}</UnorderedList.Item>
                          ou
                          <UnorderedList.Item>{t('common:tlsCheckContent:contentPointSecond')}</UnorderedList.Item>
                        </UnorderedList>

                        <FormField notion type="checkbox" htmlFor="allowUnencryptedEmails">
                          <Checkbox
                            name="consentData[2].isGiven"
                            checked={formProps.values.consentData[2]?.isGiven}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              formProps.setFieldValue('consentData[2].isGiven', e.target.checked)
                            }
                          >
                            {t('common:tlsCheckContent:checkboxContent')}
                          </Checkbox>
                          <ErrorMessage
                            name="consentData[2].isGiven"
                            render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                          />
                        </FormField>
                      </Layout.Item>
                    </ContentSection>
                  )}
                </Layout>
              </Fieldset.Row>
              {isLoading || isTlsInProgress ? (
                <Spinner center small />
              ) : (
                <ButtonContainer center>
                  <Button disabled={!formProps.isValid} type="submit" testId="continueBtn">
                    {t('landingPage:continueButton')}
                  </Button>
                </ButtonContainer>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
