import { vw } from './vw';
import { vwfs } from './vwfs';

const brands = {
  vwfs,
  // audi,
  // seat,
  // vw,
  // vwcv,
  // cupra,
  vw
};

export const SECURITY_DEPOSIT_DISPLAYED = '750 € TTC';

export const config = brands['vw' || 'vwfs'];
