const actionTypes = {
  SET_DATA_STORAGE: 'SET_DATA_STORAGE',

  SET_LEAD_FORM_DATA: 'SET_LEAD_FORM_DATA',
  SET_PERSONAL_INFO: 'SET_PERSONAL_INFO',
  SET_CONTACT_INFO: 'SET_CONTACT_INFO',
  SET_DRIVER_INFO: 'SET_DRIVER_INFO',
  SET_CONSENTS_INFO: 'SET_CONSENTS_INFO',

  SET_IS_TLS_SUPPORTED: 'SET_IS_TLS_SUPPORTED',
  SET_IS_TLS_CHECK_IN_PROGRESS: 'SET_IS_TLS_CHECK_IN_PROGRESS',

  SET_DATA_STORAGE_DATA_IS_LOADING: 'SET_DATA_STORAGE_DATA_IS_LOADING',

  SET_APP_ERROR: 'SET_APP_ERROR',
  SET_SESSION: 'SET_SESSION',
  SET_DATA_STORAGE_DATA_IS_LOADED: 'SET_DATA_STORAGE_DATA_IS_LOADED',

  SET_KEYCLOAK_DATA: 'SET_KEYCLOAK_DATA',
  SET_KEYCLOAK_IDP: 'SET_KEYCLOAK_IDP',
  SET_KEYCLOAK_IDP_HINT: 'SET_KEYCLOAK_IDP_HINT',
  SET_IS_LOGGED: 'SET_IS_LOGGED',
  SET_TRACK_AUTH_SUCCESS: 'SET_TRACK_AUTH_SUCCESS',
  SET_PAYMENT_AUTHORIZATION_STATUS: 'SET_PAYMENT_AUTHORIZATION_STATUS',
  SET_TRANSACTION_STATUS: 'SET_TRANSACTION_STATUS'
} as const;

export default actionTypes;
