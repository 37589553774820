import React, { FunctionComponent } from 'react';
import Header from '../Header/Header';
import AppWizard from '../AppWizard/AppWizard';

const Layout: FunctionComponent<any> = ({ children }) => {
  return (
    <>
      <Header />
      <AppWizard />
      {children}
    </>
  );
};

export default Layout;
