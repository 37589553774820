import { TransactionStatus } from './models/data-storage.model';

export const routes = {
  application: {
    root: '/',
    forms: '/form',
    personalInfo: '/form#personalInfo',
    contactInfo: '/form#contactInfo',
    paymentInfo: '/form#paymentInfo',
    summary: '/summary',
    payment: '/payment',
    confirmation: '/confirmation',
    continuation: '/authenticate'
  },
  linkExpired: '/expired',
  error: '/error',
  thirdPartyLicense: '/licenses',
  privacyPolicy: '/privacy',
  legalTerms: '/legal',
  cookiePolicy: '/page/cookies'
} as const;

export function routeByStatus(status: TransactionStatus) {
  switch (status) {
    case TransactionStatus.LEAD_SUBMITTED:
      if (window.location.pathname.includes(routes.application.forms)) {
        return routes.application.forms;
      }
      return routes.application.root;
    case TransactionStatus.APPLICATIONFORM_STARTED:
      return routes.application.forms;
    case TransactionStatus.PAYMENT_REJECTED:
      return routes.error;
    case TransactionStatus.CONTRACT_CONFIRMED:
      return routes.application.confirmation;
    case TransactionStatus.APPLICATIONFORM_COMPLETED:
      return routes.application.summary;
    default:
      return routes.application.root;
  }
}
