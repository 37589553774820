import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios } from '../api/apiClient';
import { AppError } from '../api/appError';
import { routes } from '../routes';
import { tracking } from '../tracking/tracking';

const ignoredPaths = ['/tan/', '/ui-logs'];

export const ApiClientConfig = ({ children }: { children: JSX.Element }): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.response.use(
      res => {
        return res;
      },
      error => {
        for (const path of ignoredPaths) {
          if (error.config.url.startsWith(path)) {
            throw error;
          }
        }
        navigate(routes.error);

        if (error.config.url.includes('payments')) {
          tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.PAYMENT_ERROR]());
        } else if (error.config.url.includes('confirmations')) {
          tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.CONFIRMATION_ERROR]());
        } else if (error?.response?.status === 500 || error?.response?.status === 400) {
          tracking.trackEvent(
            tracking.pageToAnalyticsEvent[tracking.trackedPage.TECHNICAL_ERROR](error.response.status, error.message)
          );
        } else if ([401, 403, 404].includes(error?.response?.status)) {
          tracking.trackEvent(
            tracking.pageToAnalyticsEvent[tracking.trackedPage.STANDARD_ERROR](error.response.status, error.message)
          );
        }

        // API Error handling
        throw new AppError({
          errorCausingURL: window.location.pathname,
          errorCode: error?.response?.status ?? null,
          message: error.message
        });
      }
    );
  }, []);

  return children;
};
