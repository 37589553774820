import { DataStorageObject } from '../models/data-storage.model';
import { axios } from './apiClient';

interface CreateSessionParams {
  transactionId: string;
  token: string;
}
interface SetPaymentFailureParams {
  transactionId: string;
  token: string;
}

export const createPaymentSession = async (params: CreateSessionParams): Promise<DataStorageObject> => {
  const { transactionId, token } = params;
  const response = await axios.post(
    `/payments/sessions/${transactionId}`,
    {},
    {
      headers: {
        Authorization: token
      }
    }
  );
  return response.data;
};

export const setPaymentFailure = async (params: SetPaymentFailureParams): Promise<DataStorageObject> => {
  const { transactionId, token } = params;
  const response = await axios.post(
    `/payments/failures/${transactionId}`,
    {},
    {
      headers: {
        Authorization: token
      }
    }
  );
  return response.data;
};
