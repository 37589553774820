import { EmailType } from '@bus/models';
import {
  ContentSection,
  ErrorMessage as FieldError,
  FormSection,
  FormSectionGroup,
  Heading,
  PageWrap,
  Spinner
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateQuoteDocument } from '../../api/document-generation';
import { saveFormData } from '../../api/form-data';
import { setContactInfo, setPersonalInfo, setTransactionStatus } from '../../redux/actions';
import {
  getContactInfo,
  getIsContactInfoValid,
  getIsPersonalInfoValid,
  getIsTlsSupported,
  getKeycloakCustomerData,
  getLeadFormData,
  getPersonalInfo,
  getSession
} from '../../redux/selector';
import { ConsentInfo, ContactInfo, PersonalInfo } from '../../redux/types';
import { routes } from '../../routes';
import { convertBffDateToUiDate } from '../landing-page/initialValues';
import { ContactInfoForm } from './components/ContactInfoForm/ContactInfoForm';
import { PersonalInfoForm } from './components/PersonalInfoForm/PersonalInfoForm';
import { UnencryptedEmailsForm } from './components/UnencryptedEmailsForm/UnencryptedEmailsForm';
import { contactInfoValidation, personalInfoValidation, tlsConsentValidation } from './validation';
import { completeForm } from '../../api/form-completion';
import { QuoteDispatchModal } from '../../components/QuoteDispatchModal/QuoteDispatchModal';
import { ConsentData, LocalConsentType } from '../../models/data-storage.model';
import { tracking } from '../../tracking/tracking';

export enum FormType {
  personalInfo = 'personalInfo',
  contactInfo = 'contactInfo'
}

const formsOrder = [FormType.personalInfo, FormType.contactInfo];

export const FormPage = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [openedForm, setOpenedForm] = React.useState<FormType | null>(FormType.personalInfo);

  const openedFormRef = React.useRef(openedForm);

  const [isOnSanctionsLists, setIsOnSanctionsLists] = useState(false);
  const [quoteDispatchModalShown, setQuoteDispatchModalShown] = useState<boolean>(false);
  const session = useSelector(getSession);

  const leadFormData = useSelector(getLeadFormData);
  const personalInfoData = useSelector(getPersonalInfo);
  const contactInfoData = useSelector(getContactInfo);

  const isTlsSupported = useSelector(getIsTlsSupported);

  const [tlsConsentInitialValues, setTlsConsentInitialValues] = useState<ConsentData[]>([]);

  const isPersonalInfoValid = useSelector(getIsPersonalInfoValid);
  const isContactInfoValid = useSelector(getIsContactInfoValid);

  const { hash } = useLocation();

  const keycloakCustomerData = useSelector(getKeycloakCustomerData);
  const [personalInfoFormData, setPersonalInfoFormData] = React.useState<PersonalInfo>(personalInfoData);
  const [contactInfoFormData, setContactInfoFormData] = React.useState<ContactInfo>(contactInfoData);

  React.useEffect(() => {
    if (!isTlsSupported) {
      setTlsConsentInitialValues([
        {
          consentType: LocalConsentType.ALLOW_UNENCRYPTED_EMAILS,
          isGiven:
            leadFormData.consentData.find(consent => consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS)
              ?.isGiven ?? false
        }
      ]);
    } else {
      setTlsConsentInitialValues([]);
    }
  }, [isTlsSupported]);

  React.useEffect(() => {
    setPersonalInfoFormData({
      ...personalInfoData,
      firstName: personalInfoData.firstName || keycloakCustomerData?.firstName || '',
      lastName: personalInfoData.lastName || keycloakCustomerData?.lastName || '',
      dateOfBirth: personalInfoData.dateOfBirth || convertBffDateToUiDate(keycloakCustomerData?.dateOfBirth) || ''
    });
  }, [personalInfoData, keycloakCustomerData]);

  React.useEffect(() => {
    setContactInfoFormData({
      ...contactInfoData,
      contactData: {
        ...contactInfoData.contactData,
        emails: [
          {
            type: EmailType.PERSONAL_PRIMARY,
            emailAddress:
              contactInfoData.contactData.emails?.[0]?.emailAddress || keycloakCustomerData.contactData?.email || ''
          }
        ]
      }
    });
  }, [contactInfoData, keycloakCustomerData]);

  React.useEffect(() => {
    if (!session) {
      return;
    }
  }, [session]);

  React.useEffect(() => {
    if (quoteDispatchModalShown) {
      tracking.trackEvent({
        event: 'page',
        core: {
          attributes: {
            viewChange: 'Mail with quote sent'
          }
        },
        eventInfo: [
          {
            eventType: 'pageView',
            eventAction: 'Success'
          }
        ]
      });
      // restore form's view change value for the future adobe calls:
      const formViewChange = tracking.pageToAnalyticsEvent[tracking.trackedPage.FORM_PAGE](openedFormRef.current).core
        .attributes.viewChange;
      tracking.trackEvent({
        core: {
          attributes: {
            viewChange: formViewChange
          }
        }
      });
    }
  }, [quoteDispatchModalShown]);

  React.useEffect(() => {
    openedFormRef.current = openedForm;
    if (isFirstLoad) {
      setTimeout(() => {
        tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.FORM_PAGE](openedFormRef.current));
      }, 700);
      setIsFirstLoad(false);
    }
    setTimeout(() => {
      const element = document.getElementById(`${hash.substring(1)}Form`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  }, [openedForm]);

  React.useEffect(() => {
    if (!hash || !formsOrder.includes(hash.substring(1) as FormType)) {
      changeOpenedForm(FormType.personalInfo);
      return;
    }
  }, []);

  React.useEffect(() => {
    setOpenedForm(hash ? (hash.substring(1) as FormType) : null);
  }, [hash]);

  const changeOpenedForm = (form: FormType) => {
    const newHash = `#${form}`;
    if (window.location.hash === newHash) {
      // remove hash from URL
      history.replaceState('', document.title, window.location.pathname + window.location.search);
      navigate(window.location.pathname + window.location.search);
    } else {
      // history.replaceState(null, document.title, window.location.pathname + newHash);
      navigate(window.location.pathname + newHash);
    }
  };

  const handleQuoteDocumentGeneration = async () => {
    setIsLoading(true);

    await generateQuoteDocument({ transactionId: session?.id as string, token: session?.token as string }).finally(() =>
      setIsLoading(false)
    );
  };

  const addAdditionalConsentValues = (values: ConsentInfo): ConsentInfo => {
    values.consentData.forEach(consent => {
      if (consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS) {
        consent.description = t('common:tlsCheckContent:checkboxContent') as string;
      }
    });

    return values;
  };

  const handleFormCompletionSubmit = (values: ConsentInfo) => {
    setIsLoading(true);
    const valuesUpdated = addAdditionalConsentValues(values);

    completeForm({
      transactionId: session?.id as string,
      token: session?.token as string,
      data: valuesUpdated
    })
      .then(res => {
        dispatch(setTransactionStatus(res.transaction.status));
        navigate(routes.application.summary);
      })
      .finally(() => setIsLoading(false));
  };

  const openForm = (formType: FormType) => {
    changeOpenedForm(formType);
    tracking.trackEvent({
      core: {
        attributes: {
          viewChange: tracking.formSectionToAnalyticsName[formType]
        }
      },
      eventInfo: [{ eventType: 'pageView', eventAction: 'Success' }],
      event: 'page'
    });
  };

  return (
    <PageWrap>
      {isLoading && <Spinner fullPage />}
      <QuoteDispatchModal
        quoteDispatchModalShown={quoteDispatchModalShown}
        setQuoteDispatchModalShown={setQuoteDispatchModalShown}
        handleResendDocument={handleQuoteDocumentGeneration}
      />
      <FormSectionGroup>
        <ContentSection.ComponentWrapper>
          <Heading level={6} id="formHeading">
            {t('applicationPage:paragraph1')}
            <br />
            {t('applicationPage:paragraph2')}
          </Heading>
          <FormSection
            title={t('applicationPersonInfo:title')}
            subtitle={t('applicationPersonInfo:subtitle')}
            id="personalInfoForm"
            testId="personalInfoForm"
            success={isPersonalInfoValid}
            defaultOpen={openedForm === FormType.personalInfo}
            onClick={() => openForm(FormType.personalInfo)}
          >
            <Formik
              enableReinitialize
              initialValues={personalInfoFormData}
              validationSchema={personalInfoValidation()}
              validateOnBlur={true}
              onSubmit={(values: PersonalInfo) => {
                setIsLoading(true);
                saveFormData({
                  transactionId: session?.id as string,
                  token: session?.token as string,
                  formType: 'personalInfo',
                  data: values
                })
                  .then(res => {
                    setIsOnSanctionsLists(res.isOnSanctionsLists);
                    if (res.isOnSanctionsLists) {
                      return;
                    }
                    dispatch(setPersonalInfo(values));
                    if (res.data) {
                      dispatch(setTransactionStatus(res.data.data.transaction.status));
                    }
                    tracking.trackEvent({
                      event: 'interaction',
                      eventInfo: [
                        {
                          linkInformation: 'Personal data provided',
                          eventType: 'Personal data',
                          eventAction: 'Provided'
                        }
                      ]
                    });
                    setTimeout(() => {
                      tracking.trackEvent(
                        tracking.pageToAnalyticsEvent[tracking.trackedPage.FORM_PAGE](openedFormRef.current)
                      );
                    }, 700);
                    window.location.hash = FormType.contactInfo;
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {() => {
                return <PersonalInfoForm />;
              }}
            </Formik>
          </FormSection>
        </ContentSection.ComponentWrapper>
        {isOnSanctionsLists && (
          <FieldError>
            {
              'Une erreur est survenue. En raison d’une erreur inattendue, nous ne pouvons poursuivre l’opération pour le moment. Merci de réessayer ultérieurement. '
            }
          </FieldError>
        )}

        <ContentSection.ComponentWrapper>
          <FormSection
            title={t('applicationContact:title')}
            subtitle={t('applicationContact:subtitle')}
            id="contactInfoForm"
            testId="contactInfoForm"
            success={isContactInfoValid}
            disabled={isOnSanctionsLists}
            defaultOpen={openedForm === FormType.contactInfo}
            onClick={() => openForm(FormType.contactInfo)}
          >
            <Formik
              enableReinitialize
              initialValues={contactInfoFormData}
              validationSchema={contactInfoValidation()}
              validateOnBlur={true}
              id="contactInfo"
              testId="contactInfo"
              onSubmit={(values: ContactInfo) => {
                setIsLoading(true);
                saveFormData({
                  transactionId: session?.id as string,
                  token: session?.token as string,
                  formType: 'contactInfo',
                  data: values
                })
                  .then(res => {
                    dispatch(setContactInfo(values));
                    if (res.data) {
                      dispatch(setTransactionStatus(res.data.data.transaction.status));
                    }
                    tracking.trackEvent({
                      event: 'interaction',
                      eventInfo: [
                        {
                          linkInformation: 'Contact data provided',
                          eventType: 'Contact data',
                          eventAction: 'Provided'
                        },
                        {
                          eventType: 'Finance approval',
                          eventAction: 'Request'
                        }
                      ]
                    });
                    setTimeout(() => {
                      tracking.trackEvent(
                        tracking.pageToAnalyticsEvent[tracking.trackedPage.FORM_PAGE](openedFormRef.current)
                      );
                    }, 700);
                    window.location.hash = '';
                    document.getElementById('formHeading')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {() => {
                return <ContactInfoForm />;
              }}
            </Formik>
          </FormSection>
        </ContentSection.ComponentWrapper>

        <ContentSection.ComponentWrapper>
          <Formik
            enableReinitialize
            initialValues={{
              consentData: tlsConsentInitialValues
            }}
            validationSchema={tlsConsentValidation(isTlsSupported)}
            onSubmit={handleFormCompletionSubmit}
          >
            {() => {
              return <UnencryptedEmailsForm />;
            }}
          </Formik>
        </ContentSection.ComponentWrapper>
      </FormSectionGroup>
    </PageWrap>
  );
};
