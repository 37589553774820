import React from 'react';
import { FoldOut, ProductInfo, ProductInfoSummary, DefinitionTable, InfoIcon } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { SECURITY_DEPOSIT_DISPLAYED } from '../../config';
import { DataStorageObject } from '../../models/data-storage.model';
import { DeliveryType } from '@bus/models';
import { formatPrice } from '../../utilities/formatters';

export interface ShoppingCartProps {
  dataStorageData: DataStorageObject;
}

export const ShoppingCart: React.FC<ShoppingCartProps> = ({ dataStorageData }) => {
  const { t } = useTranslation();

  return (
    <>
      <FoldOut
        productInfoSummary={
          <ProductInfoSummary
            imgAlt="The product image"
            infoLabel={t('common:shoppingCart.monthlyInstallments')}
            infoValue={formatPrice(
              dataStorageData?.data?.financialProduct.calculation?.monthlyTotalRateAmount?.grossAmount
            )}
            title={dataStorageData?.data?.vehicleData?.model?.name}
            subtitle={dataStorageData?.data?.vehicleData?.model?.description}
            imgSrc={dataStorageData?.data?.vehicleData?.model?.vehicleImages[0]?.url}
          />
        }
      >
        <ProductInfo
          title={dataStorageData?.data?.vehicleData?.model?.name}
          titleTagline={dataStorageData?.data?.vehicleData?.model?.description}
          media={<img src={dataStorageData?.data?.vehicleData?.model?.vehicleImages[0].url} />}
        >
          <DefinitionTable summary={<div>test summary</div>} title={<div>test title</div>}>
            <DefinitionTable.Section>
              <DefinitionTable.Item title={<b>{t('common:shoppingCart:basePrice')}</b>}>
                {formatPrice(dataStorageData?.data?.financialProduct.calculation?.basePriceAmount?.grossAmount)}
              </DefinitionTable.Item>
              <DefinitionTable.Item title={t('common:shoppingCart:duration')}>
                {dataStorageData?.data?.financialProduct.calculation?.duration} {t('common:shoppingCart:durationUnit')}
              </DefinitionTable.Item>
              <DefinitionTable.Item title={t('common:shoppingCart:monthlyMileage')}>
                {dataStorageData?.data?.financialProduct.calculation?.monthlyMileage}{' '}
                {t('common:shoppingCart:monthlyMileageUnit')}
              </DefinitionTable.Item>
              <DefinitionTable.Item title={t('common:shoppingCart:distributionAmount')}>
                {formatPrice(
                  dataStorageData?.data?.financialProduct.calculation?.staticCosts?.distributionAmount?.grossAmount
                )}
              </DefinitionTable.Item>
              <DefinitionTable.Item title={t('common:shoppingCart:deliveryCosts')}>
                {formatPrice(
                  dataStorageData?.data?.financialProduct.contracts[0].deliveryData?.deliveryCostComponents[0]
                    .deliveryCostAmount?.grossAmount
                )}
              </DefinitionTable.Item>
              <DefinitionTable.Item title={t('common:shoppingCart:deliveryType')}>
                {dataStorageData?.data?.financialProduct.contracts[0].deliveryData?.deliveryType ===
                DeliveryType.HOME_DELIVERY
                  ? t('common:shoppingCart:deliveryTypeHomeDelivery')
                  : t('common:shoppingCart:deliveryTypeDealershop')}
              </DefinitionTable.Item>
            </DefinitionTable.Section>
            <hr />

            <DefinitionTable.Section>
              <DefinitionTable.Item title={<b>{t('common:shoppingCart:selectedExtras')}</b>}>
                {formatPrice(dataStorageData?.data?.financialProduct.calculation?.monthlyRateAddOnsAmount?.grossAmount)}{' '}
                / {t('common:shoppingCart:durationUnit')}
              </DefinitionTable.Item>
              {dataStorageData?.data?.financialProduct.calculation?.additionalProducts?.length &&
                dataStorageData?.data?.financialProduct.calculation?.additionalProducts
                  .filter(product => product.codeFamily !== 'DELIVERY')
                  .map(product => (
                    <DefinitionTable.Item
                      title={product.name}
                      key={`${product.name}${product.additionalProductCostAmount}`}
                    >
                      {formatPrice(product.additionalProductCostAmount.grossAmount)} /{' '}
                      {t('common:shoppingCart:durationUnit')}
                    </DefinitionTable.Item>
                  ))}
            </DefinitionTable.Section>

            <hr />
            <DefinitionTable.Section summary summaryInformation={t('common:shoppingCart:infoText')}>
              <DefinitionTable.Item
                title={
                  <>
                    {t('common:shoppingCart:firstInstallment')}
                    <InfoIcon icon={'semantic-info'}>{t('common:shoppingCart:firstInstallmentTooltip')}</InfoIcon>
                  </>
                }
              >
                {formatPrice(dataStorageData?.data?.financialProduct.calculation?.firstInstallmentAmount?.grossAmount)}
              </DefinitionTable.Item>

              <DefinitionTable.Item
                title={
                  <>
                    {t('common:shoppingCart:securityDeposit')}
                    <InfoIcon icon={'semantic-info'}>{t('common:shoppingCart:securityDepositToolTip')}</InfoIcon>
                  </>
                }
              >
                {SECURITY_DEPOSIT_DISPLAYED}
              </DefinitionTable.Item>
              <DefinitionTable.Item>{t('common:shoppingCart:monthlyInstallments')} </DefinitionTable.Item>
              <DefinitionTable.Item>
                {formatPrice(dataStorageData?.data?.financialProduct.calculation?.monthlyTotalRateAmount?.grossAmount)}
              </DefinitionTable.Item>
            </DefinitionTable.Section>
          </DefinitionTable>
        </ProductInfo>
      </FoldOut>
    </>
  );
};

export default ShoppingCart;
