import { Spinner } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { TanAuthentication } from '@vwfs-its/sf-sac-frontend';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendSmsTan, validateSmsTan } from '../../api/save-and-continue';
import { brandNames } from '../../config/common';
import { setSession } from '../../redux/actions';
import { getSession } from '../../redux/selector';
import { routes } from '../../routes';
import { tracking } from '../../tracking/tracking';

export const SaveAndContinue = (): JSX.Element => {
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const [isFirstSMS, setIsFirstSMS] = React.useState<boolean>(true);

  const session = useSelector(getSession);
  const brand = process.env.REACT_APP_WEBSITE_BRAND;

  const dispatch = useDispatch();

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.SAVE_CONTINE]());
  }, []);

  const sendSMS = () => {
    setShowSpinner(true);
    if (!isFirstSMS) {
      tracking.trackEvent({
        event: 'interaction',
        eventInfo: [
          {
            linkInformation: 'Continue: Authentication: Request new TAN',
            eventType: 'interAction',
            eventAction: 'Success'
          }
        ]
      });
    }
    setIsFirstSMS(false);
    return sendSmsTan({ transactionId: session?.id as string })
      .then((res: any) => {
        if (res.message !== 'SUCCESS') {
          navigate(routes.error);
        }
      })
      .catch((e: any) => {
        if (e.response?.status === 404 || e.response?.status === 410) {
          navigate(routes.linkExpired);
        } else {
          navigate(routes.error);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const authorizeTransaction = (transactionId: string, tan: string) => {
    setShowSpinner(true);
    return validateSmsTan({
      transactionId: transactionId,
      tan: tan
    }).then(res => {
      setShowSpinner(false);
      if (res.token) {
        dispatch(
          setSession({
            id: transactionId,
            token: res.token
          })
        );
      }
    });
  };

  if (!brand) {
    return <></>;
  }

  return (
    <>
      {showSpinner && <Spinner fullPage />}
      <TanAuthentication
        version={1}
        transactionId={session?.id as string}
        productName={brandNames[brand]}
        externalLinkExpiryMessage={false}
        hideLinkExpiryButton={false}
        visible
        onError={() => {
          navigate(routes.error);
        }}
        customSendRequestTan={sendSMS}
        customSendRequestData={(tan: string, transactionId: string) => {
          return authorizeTransaction(transactionId, tan)
            .catch(() => {
              tracking.trackEvent({
                event: 'interaction',
                error: {
                  errorMessage: 'Continue: Authentication: Invalid TAN'
                },
                eventInfo: [
                  {
                    linkInformation: 'Continue: Authentication: Invalid TAN',
                    eventType: 'interAction',
                    eventAction: 'Success'
                  }
                ]
              });
              return {
                error: true
              };
            })
            .finally(() => {
              setShowSpinner(false);
            });
        }}
      />
    </>
  );
};
