import { CountryCodeType, PhoneCountryCodeType } from '@bus/models';
import { ErrorMessage as FieldError, Fieldset, FormField, InfoIcon, Layout, Select } from '@vwfs-bronson/bronson-react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/Input/Input';
import { checkTLS } from '../../../../api/tls-check';
import { getSession } from '../../../../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { setIsTlsCheckInProgress, setTlsIsSupported } from '../../../../redux/actions';

export const AddressFieldset = ({
  addressNamePrefix,
  contactNamePrefix,
  withContactData = false
}: {
  addressNamePrefix: string;
  contactNamePrefix: string;
  withContactData?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const formikProps = useFormikContext();
  const session = useSelector(getSession);
  const dispatch = useDispatch();

  const performTlsCheck = (email: string) => {
    dispatch(setIsTlsCheckInProgress(true));
    checkTLS({ email, transactionId: session?.id as string, token: session?.token as string })
      .then(res => dispatch(setTlsIsSupported(res.tlsSupported)))
      .catch(() => dispatch(setTlsIsSupported(false)))
      .finally(() => dispatch(setIsTlsCheckInProgress(false)));
  };

  return (
    <Fieldset>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactAddressStreet"
              labelText={t('applicationContact:customerData.addresses.street')}
              infoIcon={<InfoIcon icon={'semantic-info'}>{t('applicationContact:tooltips.street')}</InfoIcon>}
            >
              <Field component={Input} name={`${addressNamePrefix}street`} />
              <ErrorMessage
                name={`${addressNamePrefix}street`}
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              type="input"
              htmlFor="fieldContactAddressHouse"
              labelText={t('applicationContact:customerData.addresses.houseNumber')}
            >
              <Field component={Input} name={`${addressNamePrefix}houseNumber`} onBlur={formikProps.handleBlur} />
              <ErrorMessage
                name={`${addressNamePrefix}houseNumber`}
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactAddressZipCode"
              labelText={t('applicationContact:customerData.addresses.zipCode')}
            >
              <Field component={Input} name={`${addressNamePrefix}zipCode`} />
              <ErrorMessage
                name={`${addressNamePrefix}zipCode`}
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactAddressCity"
              labelText={t('applicationContact:customerData.addresses.city')}
            >
              <Field component={Input} name={`${addressNamePrefix}city`} />
              <ErrorMessage
                name={`${addressNamePrefix}city`}
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>

      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              notion
              type="input"
              htmlFor="fieldContactAddressCountry"
              labelText={t('applicationContact:customerData.addresses.country')}
            >
              <Field as={Select} name={`${addressNamePrefix}countryCode`}>
                <Select.Item value={CountryCodeType.France}>
                  {t('applicationContact:customerData.addresses.countryFranceLabel')}
                </Select.Item>
              </Field>
              <ErrorMessage
                name={`${addressNamePrefix}countryCode`}
                render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
              />
            </FormField>
          </Layout.Item>
          {withContactData && (
            <Layout.Item default="1/2" s="1/1">
              <FormField
                notion
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value) {
                    performTlsCheck(e.target.value);
                  }
                }}
                type="input"
                htmlFor="fieldContactEmail"
                labelText={t('applicationContact:customerData.contactData.emailAddress')}
                infoIcon={<InfoIcon icon={'semantic-info'}>{t('applicationContact:tooltips.email')}</InfoIcon>}
              >
                <Field component={Input} name={`${contactNamePrefix}emails[0].emailAddress`} />
                <ErrorMessage
                  name={`${contactNamePrefix}emails[0].emailAddress`}
                  render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                />
              </FormField>
            </Layout.Item>
          )}
        </Layout>
      </Fieldset.Row>
      {withContactData && (
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                type="input"
                notion
                htmlFor="fieldContactCountryCode"
                labelText={t('applicationContact:customerData.contactData.countryCode')}
              >
                <Field as={Select} name={`${contactNamePrefix}mobilePhoneCountryCode`}>
                  {Object.entries(PhoneCountryCodeType).map(([countryLabel, countryCode]) => (
                    <Select.Item key={`${countryCode}${countryLabel}`} value={countryCode}>
                      {`${countryCode} ${countryLabel.replace(/([A-Z])/g, ' $1').trim()}`}
                    </Select.Item>
                  ))}
                </Field>
                <ErrorMessage
                  name={`${contactNamePrefix}mobilePhoneCountryCode}`}
                  render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                />
              </FormField>
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                type="input"
                notion
                htmlFor="fieldContactMobPhone"
                labelText={t('applicationContact:customerData.contactData.mobilePhoneNumber')}
                infoIcon={
                  <InfoIcon icon={'semantic-info'}>{t('applicationContact:tooltips.mobilePhoneNumber')}</InfoIcon>
                }
              >
                <Field component={Input} name={`${contactNamePrefix}mobilePhoneNumber`} />
                <ErrorMessage
                  name={`${contactNamePrefix}mobilePhoneNumber`}
                  render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
                />
              </FormField>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
      )}
    </Fieldset>
  );
};
