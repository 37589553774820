import { BackToTop } from '@vwfs-bronson/bronson-react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApiClientConfig } from './components/ApiClientConfig';
import AppFooter from './components/Footer/Footer';
import { WithIdentityBroker } from './components/IdentityBroker/WithIdentityBroker';
import Layout from './components/Layout/Layout';
import { WithDataLoaded } from './pages/application-page-wrapper/WithDataLoaded';
import ConfirmationPage from './pages/confirmation-page/confirmationPage';
import ErrorPage from './pages/error-page/ErrorPage';
import { FormPage } from './pages/form-page/FormPage';
import LandingPage from './pages/landing-page/landingPage';
import { LinkExpired } from './pages/link-expired/LinkExpired';
import SummaryPage from './pages/summary-page/summaryPage';
import { routes } from './routes';
import { TransactionStatus } from './models/data-storage.model';
import { UiLog, UiLogsStack } from './models/ui-logs.model';
import { useSelector } from 'react-redux';
import { getToken, getTransactionId } from './redux/selector';
import { sendUiLogs } from './api/ui-logs';
import { tracking } from './tracking/tracking';
import PaymentPage from './pages/payment-page/paymentPage';

const redirectURLs: { [key: string]: string } = {
  int: 'https://int.volkswagenabonnement.vwfs.fr',
  cons: 'https://kons.volkswagenabonnement.vwfs.fr'
};
const stage = process.env.REACT_APP_STAGE as string;
export const REDIRECT_URL = Object.keys(redirectURLs).includes(stage)
  ? redirectURLs[stage]
  : 'https://volkswagenabonnement.vwfs.fr';

// used to identify the window/tab in the UI logs sent to FES
export const WINDOW_ID = Math.random()
  .toString(36)
  .slice(2, 8)
  .toUpperCase();

const uiLogsStack = new UiLogsStack();
export const UiLogsContext = React.createContext<UiLogsStack>(uiLogsStack);

const App = (): JSX.Element => {
  const { t } = useTranslation();

  const token = useSelector(getToken);
  const transactionId = useSelector(getTransactionId);

  React.useEffect(() => {
    tracking.initTracking();
  }, []);

  React.useEffect(() => {
    if (token && transactionId) {
      const sendMethod = (messages: UiLog[]) =>
        sendUiLogs({
          messages: messages,
          windowId: WINDOW_ID,
          token: token,
          transactionId: transactionId
        });
      uiLogsStack?.setSendMethod(sendMethod);
    } else {
      uiLogsStack?.setSendMethod(null);
    }
  }, [token, transactionId]);

  React.useEffect(() => {
    const beforeUnloadListener = () => {
      if (uiLogsStack) {
        return uiLogsStack.flushUiLogs();
      }
    };
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  return (
    <BrowserRouter>
      <Suspense>
        <UiLogsContext.Provider value={uiLogsStack}>
          <WithIdentityBroker>
            <Layout>
              <ApiClientConfig>
                <>
                  <Routes>
                    <Route
                      path={routes.application.root}
                      element={
                        <WithDataLoaded
                          permittedStatuses={[
                            TransactionStatus.INITIALIZED,
                            TransactionStatus.LEAD_SUBMITTED,
                            TransactionStatus.APPLICATIONFORM_STARTED
                          ]}
                        >
                          <LandingPage />
                        </WithDataLoaded>
                      }
                    />
                    <Route
                      path={routes.application.forms}
                      element={
                        <WithDataLoaded
                          permittedStatuses={[
                            TransactionStatus.LEAD_SUBMITTED,
                            TransactionStatus.APPLICATIONFORM_STARTED
                          ]}
                        >
                          <FormPage />
                        </WithDataLoaded>
                      }
                    />
                    <Route
                      path={routes.application.summary}
                      element={
                        <WithDataLoaded permittedStatuses={[TransactionStatus.APPLICATIONFORM_STARTED]}>
                          <SummaryPage />
                        </WithDataLoaded>
                      }
                    />
                    <Route
                      path={routes.application.payment}
                      element={
                        <WithDataLoaded permittedStatuses={[TransactionStatus.APPLICATIONFORM_COMPLETED]}>
                          <PaymentPage />
                        </WithDataLoaded>
                      }
                    />
                    <Route path={routes.application.confirmation} element={<ConfirmationPage />} />
                    <Route path={routes.error} element={<ErrorPage />} />
                    <Route path={routes.linkExpired} element={<LinkExpired />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </>
              </ApiClientConfig>
              <BackToTop>{t('common:backToTop')}</BackToTop>
              <AppFooter />
            </Layout>
          </WithIdentityBroker>
        </UiLogsContext.Provider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
