import React from 'react';
import { PageWrap, ContentSection, Spinner, FormSection, ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DataStorageObject } from '../../models/data-storage.model';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaymentResult, RootState, Session } from '../../redux/types';
import '../index.css';
import { PaymentInfo } from '../form-page/components/PaymentInfoForm/PaymentInfoForm';
import { getPaymentAuthorizationStatus } from '../../redux/selector';
import { setPaymentFailure } from '../../api/payments';
import { setTransactionStatus } from '../../redux/actions';
import { routes } from '../../routes';
import { createOrderTransaction } from '../../api/create-order';
import { tracking } from '../../tracking/tracking';

export interface PaymentProps {
  dataStorage: DataStorageObject | null;
  session: Session;
}

const PaymentPage: React.FC<PaymentProps> = ({ dataStorage, session }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentAuthorizationStatus = useSelector(getPaymentAuthorizationStatus);

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.PAYMENT]());
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [submitPaymentForm, setSubmitPaymentForm] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (paymentAuthorizationStatus === PaymentResult.AUTHORIZED) {
      createOrderTransaction({
        transactionId: session.id,
        token: session.token as string
      })
        .then(res => {
          dispatch(setTransactionStatus(res.data.transaction.status));
          navigate(routes.application.confirmation);
        })
        .catch(() => {
          navigate(routes.error);
        })
        .finally(() => setIsLoading(false));
    } else if (
      paymentAuthorizationStatus === PaymentResult.REFUSED ||
      paymentAuthorizationStatus === PaymentResult.ERROR
    ) {
      setIsLoading(true);
      setPaymentFailure({
        transactionId: session?.id as string,
        token: session?.token as string
      })
        .then(res => {
          dispatch(setTransactionStatus(res.data.transaction.status));
          navigate(routes.error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [paymentAuthorizationStatus]);

  return (
    <PageWrap>
      {isLoading && <Spinner fullPage />}
      <ContentSection>
        <ContentSection.ComponentWrapper>
          <FormSection
            title={t('applicationPayment:title')}
            subtitle={
              <span>
                {t('applicationPayment:subtitle')}
                <br />
                <span dangerouslySetInnerHTML={{ __html: t('applicationPayment:termsLink') as string }} />
              </span>
            }
            id="paymentInfoForm"
            success={paymentAuthorizationStatus === PaymentResult.AUTHORIZED}
            testId="paymentInfoForm"
            defaultOpen={true}
          >
            <PaymentInfo submitPaymentForm={submitPaymentForm} setIsFormValid={setIsFormValid} />
          </FormSection>
          <ButtonContainer center>
            <Button
              inContainer
              type="submit"
              disabled={!isFormValid}
              onClick={() => {
                setIsLoading(true);
                setSubmitPaymentForm(true);
              }}
            >
              {t('summaryPage:submitButtonText')}
            </Button>
          </ButtonContainer>
        </ContentSection.ComponentWrapper>
      </ContentSection>
    </PageWrap>
  );
};

function mapStateToProps(state: RootState) {
  return {
    dataStorage: state.dataStorage,
    session: state.session as Session
  };
}

export default connect(mapStateToProps)(PaymentPage);
