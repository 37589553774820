import { axios } from './apiClient';
import { DataStorageObject } from '../models/data-storage.model';

export type GetDataStorageDataParams = {
  transactionId: string;
  token: string;
};

export const getDataStorageData = async (params: GetDataStorageDataParams): Promise<DataStorageObject> => {
  const { transactionId, token } = params;
  const response = await axios.get<DataStorageObject>(`/data/${transactionId}`, {
    headers: {
      Authorization: token
    }
  });
  return response.data;
};
