import {AnalyticsDataLayer} from './datalayer.model';
import {DeepPartial} from 'redux';
import {DataStorageObject} from '../models/data-storage.model';
import {FormType} from "../pages/form-page/FormPage";
import {initialDataLayer} from "./datalayer";

declare global {
  interface Window {
    adobeDataLayer: DeepPartial<AnalyticsDataLayer>[];
  }
}

function getBrowserResolutionBreakpoint() {
  const width = window.innerWidth;
  if (width >= 1920) {
    return 'xxl';
  }
  if (width >= 1600) {
    return 'xl';
  }
  if (width >= 1280) {
    return 'l';
  }
  if (width >= 960) {
    return 'm';
  }
  if (width >= 720) {
    return 's';
  }
  if (width < 720) {
    return 'xs';
  }
}


const formSectionToAnalyticsName: Record<FormType, string> = {
  [FormType.personalInfo]: 'Personal data',
  [FormType.contactInfo]: 'Contact data',
};

enum AnalyticsPage {
  LANDING = 'landing',
  LEAD_DATA_FORM = 'lead-data-form',
  FORM_PAGE = 'form-page',
  SUMMARY = 'summary',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
  CONTACT = 'contact',
  SAVE_CONTINE = 'save-continue',
  LINK_EXPIRED = 'link-expired',

  TECHNICAL_ERROR = 'technical-error',
  STANDARD_ERROR = 'standard-error',
  CONFIRMATION_ERROR = 'confirmation-error',
  PAYMENT_ERROR = 'payment-error',
}


const pageToAnalyticsEvent = {
  [AnalyticsPage.LANDING]: () => ({
    core: {
      pageInfo: {
        pageName: 'home',
      },
      attributes: {
        viewChange: 'home'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
      {eventType: 'Product Configuration', eventAction: 'Completed'},
      {eventType: 'Product Price', eventAction: 'Calculated'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.LEAD_DATA_FORM]: () => ({
    event: 'page',
    core: {
      pageInfo: {
        pageName: 'home',
      },
      attributes: {
        viewChange: 'Save application form'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
    ]
  }),
  [AnalyticsPage.FORM_PAGE]: (formSection: FormType | null) => ({
    core: {
      pageInfo: {
        pageName: 'Application form',
      },
      attributes: {
        viewChange: formSection ? formSectionToAnalyticsName[formSection] : 'Application form'
      }
    },
    form: {
      type: formSection || null,
      name: formSection || null,
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.SUMMARY]: () => ({
    core: {
      pageInfo: {
        pageName: 'Summary',
      },
      attributes: {
        viewChange: 'Summary'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
      {eventType: 'Required contract data', eventAction: 'Provided'},
      {eventType: 'Finance approval', eventAction: 'Success'},
      {eventType: 'SummaryPage', eventAction: 'Success'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.PAYMENT]: () => ({
    core: {
      pageInfo: {
        pageName: 'Payment page',
      },
      attributes: {
        viewChange: 'Payment page'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
      {eventType: 'Payment', eventAction: 'Request'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.CONFIRMATION]: () => ({
    core: {
      pageInfo: {
        pageName: 'Confirmation',
      },
      attributes: {
        viewChange: 'Confirmation'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
      {eventType: 'Payment', eventAction: 'Success'},
      {eventType: 'Sale', eventAction: 'Success'},
      {eventType: 'Product journey', eventAction: 'End'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.CONTACT]: () => ({
    core: {
      attributes: {
        viewChange: 'Contact'
      }
    },
    eventInfo: [
      {eventType: 'interaction', eventAction: 'Success'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.CONFIRMATION_ERROR]: () => ({
    core: {
      pageInfo: {
        pageName: 'Order creation failed',
      },
      attributes: {
        viewChange: 'Order creation failed'
      }
    },
    eventInfo: [
      {eventType: 'Order creation', eventAction: 'Failed'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.PAYMENT_ERROR]: () => ({
    core: {
      pageInfo: {
        pageName: 'Payment failed',
      },
      attributes: {
        viewChange: 'Payment failed - rejected'
      }
    },
    eventInfo: [
      {eventType: 'Payment', eventAction: 'Failed'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.SAVE_CONTINE]: () => ({
    core: {
      pageInfo: {
        pageName: 'Continue: Authentication',
      },
      attributes: {
        viewChange: 'TAN input'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.LINK_EXPIRED]: () => ({
    core: {
      pageInfo: {
        pageName: 'Continue: Link expired',
      },
      attributes: {
        viewChange: 'Continue: Link expired'
      }
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.TECHNICAL_ERROR]: (errorCode?: number, errorMessage?: string) => ({
    core: {
      pageInfo: {
        pageName: 'Technical error',
      },
      attributes: {
        viewChange: 'Technical error'
      }
    },
    error: {
      errorCode: errorCode || null,
      errorMessage: errorMessage || null,
      errorCausingURL: window.location.pathname,
    },
    eventInfo: [
      {eventType: 'pageView', eventAction: 'Success'},
      {eventType: 'Error', eventAction: 'Technical error'},
    ],
    event: 'page'
  }),
  [AnalyticsPage.STANDARD_ERROR]: (errorCode?: number, errorMessage?: string) => {
    const eventType = {
      401: '401: Login',
      403: '403: Access',
      404: '404: Error',
    };
    const eventAction = {
      401: '401: Unauthorized',
      403: '403: Denied',
      404: '404: Page not found',
    };
    return {
      core: {
        pageInfo: {
          pageName: 'Error',
        },
        attributes: {
          viewChange: 'Error'
        }
      },
      error: {
        errorCode: errorCode || null,
        errorMessage: errorMessage || null,
        errorCausingURL: window.location.pathname,
      },
      eventInfo: [
        {eventType: 'pageView', eventAction: 'Success'},
        {eventType: eventType[errorCode as 401] || 'Error', eventAction: eventAction[errorCode as 401] || 'Error'},
      ],
      event: 'page'
    };
  },
  // eslint-disable-next-line prettier/prettier
} satisfies Record<AnalyticsPage, (...params: any[]) => DeepPartial<AnalyticsDataLayer>>;

class Tracking {
  pageToAnalyticsEvent: typeof pageToAnalyticsEvent = pageToAnalyticsEvent;
  formSectionToAnalyticsName = formSectionToAnalyticsName;
  trackedPage = AnalyticsPage;

  initTracking() {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      design: {
        browserResolutionBreakpoint: getBrowserResolutionBreakpoint()
      },
      core: {
        stagingEnvironment: process.env.REACT_APP_STAGE,
        attributes: {
          brand: 'vw',
          journeyType: 'customer-facing-product-journey',
          SandCtype: 'Active'
        },
        pageInfo: {
          version: 'R1.0',
          releaseDate: '2023-06-01',
          language: 'fr',
          market: 'FR',
          publisher: 'local-FR'
        },
        category: {
          primaryCategory: 'Renting',
          secondaryCategory: 'Subscription',
          siteType: 'checkoutSite',
          inventoryType: 'Asset-based products',
          maturityLevel: 'Online contract',
          contractType: 'New'
        }
      },
      product: [
        {
          category: 'Leasing',
          name: 'Subscription',
          attributes: {
            currency: 'EUR',
            typeOfSale: 'Online sales',
            paymentFrequency: 'Monthly payment',
            paymentType: 'Credit Card'
          }
        }
      ],
      customerData: {
        loginStatus: false,
        loggedInUserGroup: 'private'
      }
    });
  }

  trackEvent(event: DeepPartial<AnalyticsDataLayer>) {
    event.error = {
      ...initialDataLayer.error,
      ...(event.error || {})
    };
    event.form = {
      ...initialDataLayer.form,
      ...(event.form || {})
    };
    window.adobeDataLayer.push(event);
  }

  extendTrackingDataWithStorefront(dataStorageObject: DataStorageObject) {
    window.adobeDataLayer.push({
      core: {
        attributes: {
          transactionID: dataStorageObject?.id ?? null,
        }
      },
      product: [
        {
          attributes: {
            contractAmount:
              (dataStorageObject.data.financialProduct?.calculation?.monthlyTotalRateAmount?.grossAmount || 0) *
              (dataStorageObject.data.financialProduct?.calculation?.duration || 0) || null,
            recurringPayment:
              dataStorageObject.data.financialProduct?.calculation?.monthlyTotalRateAmount?.grossAmount ?? null,
            duration: dataStorageObject.data.financialProduct?.calculation?.duration ?? null,
            durationUnit: dataStorageObject.data.financialProduct?.calculation?.durationUnit ?? 'MONTHS',
            yearlyMileage: (dataStorageObject.data.financialProduct?.calculation?.monthlyMileage || 0) * 12 || null,
            contractMileage:
              (dataStorageObject.data.financialProduct?.calculation?.monthlyMileage || 0) *
              (dataStorageObject.data.financialProduct?.calculation?.duration || 0) || null,
            mileageUnit: dataStorageObject?.data?.financialProduct?.calculation?.mileageUnit || 'KILOMETERS',
            addOns: dataStorageObject.data.financialProduct?.calculation?.additionalProducts?.map(addOn => ({
              name: addOn.name
            }))
          },
          vehicleModel: [
            {
              manufacturer: dataStorageObject.data.vehicleData?.model?.manufacturer || null,
              name: dataStorageObject.data.vehicleData?.model?.name || null,
              descriptionLong:
                dataStorageObject.data.vehicleData?.model?.descriptionLong ||
                dataStorageObject.data.vehicleData?.model?.description ||
                null
            }
          ]
        }
      ],
      dealerData: {
        companyId: dataStorageObject.data.dealerData?.companyId,
        companyName: dataStorageObject.data.dealerData?.companyName,
        address: {
          street: dataStorageObject.data.dealerData?.addresses?.[0]?.street,
          city: dataStorageObject.data.dealerData?.addresses?.[0]?.city,
          zipCode: dataStorageObject.data.dealerData?.addresses?.[0]?.zipCode
        }
      }
    });
  }

  extendTrackingData(data: DeepPartial<AnalyticsDataLayer>) {
    window.adobeDataLayer.push(data);
  }
}

export const tracking = new Tracking();
