export enum UiLogLevel {
  info = 'info',
  warn = 'warn',
  error = 'error'
}

export interface UiLog {
  level: UiLogLevel;
  timestamp: string;
  content: string | { message: string; details?: any };
}

export class UiLogsStack {
  private uiLogsStackMaxSize = 5;
  private uiLogsStack: UiLog[] = [];
  private sendMethod: ((messages: UiLog[]) => Promise<any>) | null = null;

  private getAndClearUiLogs(): UiLog[] {
    return this.uiLogsStack.splice(0, this.uiLogsStack.length);
  }

  public setSendMethod(sendMethod: ((messages: UiLog[]) => Promise<any>) | null): void {
    this.sendMethod = sendMethod;
  }

  public addUiLog(level: UiLogLevel, content: string | { message: string; details?: any }): void {
    const timestamp = new Date().toISOString();

    this.uiLogsStack.push({
      level,
      content,
      timestamp
    });
    if (level === UiLogLevel.error || this.uiLogsStack.length > this.uiLogsStackMaxSize) {
      this.flushUiLogs();
    }
  }

  public async flushUiLogs(): Promise<void> {
    if (this.sendMethod) {
      const messages = this.getAndClearUiLogs();
      return this.sendMethod(messages);
    }
  }
}
