import React from 'react';
import {
  ContentSection,
  Heading,
  Paragraph,
  UnorderedList,
  Checkbox,
  FormField,
  ErrorMessage as FieldError,
  ButtonContainer,
  Button
} from '@vwfs-bronson/bronson-react';
import { Form, ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ConsentInfo } from '../../../../redux/types';
import { useSelector } from 'react-redux';
import { getAreFormSectionsValid, getIsTlsSupported } from '../../../../redux/selector';
import { LocalConsentType } from '../../../../models/data-storage.model';

export const UnencryptedEmailsForm = (): JSX.Element => {
  const { values, setFieldValue, isValid }: FormikProps<ConsentInfo> = useFormikContext();
  const { t } = useTranslation();

  const allowUnencryptedEmailsConsentIndex = values?.consentData?.findIndex(
    consent => consent.consentType === LocalConsentType.ALLOW_UNENCRYPTED_EMAILS
  );
  const isTlsSupported = useSelector(getIsTlsSupported);
  const areFormSectionsValid = useSelector(getAreFormSectionsValid);

  return (
    <Form>
      {!isTlsSupported && (
        <ContentSection>
          <Heading level={5}>{t('common:tlsCheckContent:heading')}</Heading>
          <Paragraph>{t('common:tlsCheckContent:contentTxt')}</Paragraph>
          <UnorderedList>
            <UnorderedList.Item>{t('common:tlsCheckContent:contentPointFirst')}</UnorderedList.Item>
            ou
            <UnorderedList.Item>{t('common:tlsCheckContent:contentPointSecond')}</UnorderedList.Item>
          </UnorderedList>

          <FormField notion type="checkbox" htmlFor="allowUnencryptedEmails">
            <Checkbox
              name={`consentData[${allowUnencryptedEmailsConsentIndex}].isGiven`}
              checked={values && values.consentData[allowUnencryptedEmailsConsentIndex]?.isGiven}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`consentData[${allowUnencryptedEmailsConsentIndex}].isGiven`, e.target.checked)
              }
            >
              {t('common:tlsCheckContent:checkboxContent')}
            </Checkbox>
            <ErrorMessage
              name={`consentData[${allowUnencryptedEmailsConsentIndex}].isGiven`}
              render={(msg: string) => <FieldError>{t(msg)}</FieldError>}
            />
          </FormField>
        </ContentSection>
      )}
      <ButtonContainer center>
        <Button type="submit" inContainer disabled={!isValid || !areFormSectionsValid}>
          {t('applicationPersonInfo:continueBtn')}
        </Button>
      </ButtonContainer>
    </Form>
  );
};
