interface AppErrorPayload {
  message?: string;
  errorCausingURL?: string;
  errorCode?: string;
}

export class AppError extends Error {
  errorDetails: AppErrorPayload;

  constructor(errorDetails: AppErrorPayload) {
    super();
    this.errorDetails = errorDetails;
  }
}
