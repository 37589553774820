import React, { FunctionComponent } from 'react';
import { ElementId, Field, Form } from '../Input/model';
import { RadioButton as BronsonRadioButton } from '@vwfs-bronson/bronson-react';

export type RadioButtonProps = ElementId & {
  viewValue: string;
  field: Field;
  value: any;
  form: Form;
};

export const RadioButton: FunctionComponent<RadioButtonProps> = ({ id, viewValue, field, value }) => {
  return (
    <BronsonRadioButton id={id} type="radio" {...field} value={value} checked={value === field.value}>
      {viewValue}
    </BronsonRadioButton>
  );
};
