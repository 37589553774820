import React, { useState } from 'react';
import { Modal, Button, Layout, ItemTeaser, Paragraph, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { tracking } from '../../tracking/tracking';

export const ContactModal = (): JSX.Element => {
  const [contactModalShown, setContactModalShown] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Layout.Item default="1/6" s="1/1">
      <Button
        icon="semantic-mail"
        small
        simple
        link
        onClick={() => {
          setContactModalShown(true);
          tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.CONTACT]());
        }}
      >
        {t('contactModal:buttonLabel')}
      </Button>
      <Modal
        id="contactModal"
        onCancel={() => setContactModalShown(false)}
        onClickOutside={() => {
          setContactModalShown(false);
        }}
        onClose={() => setContactModalShown(false)}
        shown={contactModalShown}
        title={t('contactModal:header')}
        center
      >
        <Layout center>
          <Layout.Item>
            <ItemTeaser inline icon="semantic-mail">
              <Paragraph>{t('contactModal:subheader')}</Paragraph>
              <Paragraph>{t('contactModal:contactText')}</Paragraph>
              <Paragraph>
                {t('contactModal:supportEmailText')}
                <Link normalLink href={`mailto:${t('contactModal:supportEmail')}`}>
                  {t('contactModal:supportEmail')}
                </Link>
              </Paragraph>
              <Paragraph>
                {t('contactModal:phoneLabel')} : {t('contactModal:supportPhoneNumber')}
              </Paragraph>
            </ItemTeaser>
          </Layout.Item>
        </Layout>
      </Modal>
    </Layout.Item>
  );
};
