import * as Yup from 'yup';
import { LocalConsentType } from '../../models/data-storage.model';

export const summaryConsentsValidationSchema = Yup.object().shape({
  consentData: Yup.tuple([
    Yup.object().shape({
      consentType: Yup.string()
        .oneOf([LocalConsentType.MARKETING_ADS], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      isGiven: Yup.boolean()
        .oneOf([true, false], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField')
    }),
    Yup.object().shape({
      consentType: Yup.string()
        .oneOf([LocalConsentType.PHONE_ADS], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      isGiven: Yup.boolean()
        .oneOf([true, false], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField')
    }),
    Yup.object().shape({
      consentType: Yup.string()
        .oneOf([LocalConsentType.DATA_SHARING_ADS], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      isGiven: Yup.boolean()
        .oneOf([true, false], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField')
    }),
    Yup.object().shape({
      consentType: Yup.string()
        .oneOf([LocalConsentType.TERMS_AND_CONDITIONS], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField'),
      isGiven: Yup.boolean()
        .oneOf([true], 'validation:errorMessages:requiredField')
        .required('validation:errorMessages:requiredField')
    })
  ])
});
