import React, { FunctionComponent } from 'react';
import { Footer as BrFooter, Footnote } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import pdfFile from '../../resources/files/OSS_Licenses_ABOFR_v1.0.pdf';

type FooterItem = {
  title: string;
  links: { name: string; url: string; pdf?: string }[];
};

const AppFooter: FunctionComponent = () => {
  const { t } = useTranslation();

  const footerColumns: FooterItem[] = t('common:footer.columns', { returnObjects: true });
  const footerNavLinks: FooterItem['links'] = t('common:footer.navLinks', { returnObjects: true });

  const navLinkPdfs = { third_party_licenses: pdfFile };

  return (
    <BrFooter testId="appFooter">
      <BrFooter.ModuleNavColumns>
        {footerColumns.map(column => (
          <BrFooter.NavColumn title={column.title} key={column.title}>
            {column.links.map(link => (
              <BrFooter.NavColumn.Item
                key={link.url}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                linkProps={{
                  target: '_blank',
                  normalLink: 'normalLink'
                }}
              >
                {link.name}
              </BrFooter.NavColumn.Item>
            ))}
          </BrFooter.NavColumn>
        ))}
      </BrFooter.ModuleNavColumns>
      <BrFooter.ModuleMetaNav copyrightText={t('common:footer.copyright')}>
        {footerNavLinks.map(link => (
          <BrFooter.ModuleMetaNav.Item
            key={link.url}
            href={link.pdf ? navLinkPdfs[link.pdf as keyof typeof navLinkPdfs] : link.url}
            target="_blank"
            linkProps={{
              target: '_blank',
              normalLink: 'normalLink'
            }}
          >
            {link.name}
          </BrFooter.ModuleMetaNav.Item>
        ))}
      </BrFooter.ModuleMetaNav>
      <BrFooter.ModuleFootnotes>
        <Footnote>{t('common:footer.legalNotice')}</Footnote>
        <br />
        <Footnote>{t('common:footer.legalNotice2')}</Footnote>
        <br />
        <Footnote>{t('common:footer.legalNotice3')}</Footnote>
      </BrFooter.ModuleFootnotes>
    </BrFooter>
  );
};

export default AppFooter;
