import { t } from 'i18next';

export const formatPrice = (price: number | null | undefined) => {
  if (typeof price !== 'number') {
    return '';
  }
  return `${price.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })} ${t('common:shoppingCart:currencySymbol')}`;
};
