import React from 'react';
import { Button, ContentSection, Heading, PageWrap, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { REDIRECT_URL } from '../../App';
import { tracking } from '../../tracking/tracking';

export const LinkExpired = (): JSX.Element => {
  const { t } = useTranslation();

  React.useEffect(() => {
    tracking.trackEvent(tracking.pageToAnalyticsEvent[tracking.trackedPage.LINK_EXPIRED]());
  }, []);

  return (
    <PageWrap>
      <ContentSection className="u-text-center">
        <Heading level={3}>{t('errorPage:linkExpired.header')}</Heading>

        <Paragraph>{t('errorPage:linkExpired.paragraph1')}</Paragraph>
        <Paragraph>{t('errorPage:linkExpired.paragraph2')}</Paragraph>

        <Button
          type="button"
          onClick={() => {
            tracking.trackEvent({
              event: 'interaction',
              eventInfo: [
                {
                  linkInformation: 'Continue: Link expired: Back to purchase',
                  eventType: 'interAction',
                  eventAction: 'Success'
                }
              ]
            });
            window.location.href = REDIRECT_URL;
          }}
        >
          {t('errorPage:backButton')}
        </Button>
      </ContentSection>
    </PageWrap>
  );
};
