import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';

import {
  setTrackAuthSuccess,
  setIsLogged,
  setKeycloakData,
  setKeycloakIdp,
  setKeycloakIdpHint
} from '../../redux/actions';

import { AuthClientEvent } from '@react-keycloak/core';
import { useNavigate } from 'react-router';
import { KeycloakUserInfo } from '../../redux/types';
import { isIdentityBrokerEnabled, keycloakBrand, keycloakClient } from '../../resources/identity-brocker/keycloak';
import { routes } from '../../routes';
import { tracking } from '../../tracking/tracking';

export const WithIdentityBroker = ({ children }: { children: JSX.Element }): JSX.Element => {
  if (!isIdentityBrokerEnabled()) {
    return children;
  }

  const keycloakInstance = keycloakClient[keycloakBrand].kc;

  const [isKeycloakReady, setIsKeycloakReady] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setAuthLogoutCallback = async () => {
    keycloakInstance.onAuthLogout = async () => {
      dispatch(setIsLogged(false));
    };
  };

  const onOauthConfirm = async () => {
    dispatch(setIsLogged(true));
    if (keycloakInstance.token) {
      try {
        const userinfo = (await keycloakInstance.loadUserInfo()) as KeycloakUserInfo;
        dispatch(setKeycloakData(userinfo.customerData));
      } catch (e) {
        navigate(routes.error);
      }
    }
  };

  React.useEffect(() => {
    if (isKeycloakReady && keycloakInstance.authenticated) {
      dispatch(setIsLogged(true));
      setAuthLogoutCallback();
      onOauthConfirm();
    }
  }, [keycloakInstance, isKeycloakReady]);

  React.useEffect(() => {
    const urlSearchPrams = new URLSearchParams(window.location.search);
    const kc = urlSearchPrams.get('kc');
    if (kc && kc === keycloakClient.vwfs.name) {
      dispatch(setKeycloakIdp(keycloakClient.vwfs.name));
      dispatch(setKeycloakIdpHint(keycloakClient.vwfs.idpHint));
    } else {
      dispatch(setKeycloakIdp(keycloakClient[keycloakBrand].name));
      dispatch(setKeycloakIdpHint(keycloakClient[keycloakBrand].idpHint));
    }
  }, [setKeycloakIdp, setKeycloakIdpHint]);

  const eventLogger = (e: AuthClientEvent) => {
    if (e === 'onReady' || e === 'onInitError') {
      setIsKeycloakReady(true);
    } else if (e === 'onAuthSuccess') {
      dispatch(setTrackAuthSuccess(true));
    } else if (e === 'onAuthError') {
      tracking.trackEvent({
        event: 'page',
        customerData: {
          loginStatus: false
        },
        core: {
          attributes: {
            viewChange: 'Login failed'
          }
        },
        eventInfo: [
          {
            eventType: 'Login',
            eventAction: 'Failed'
          }
        ]
      });
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      initOptions={{
        onLoad: 'check-sso',
        checkLoginIframe: false
      }}
      onEvent={eventLogger}
    >
      {isKeycloakReady ? children : null}
    </ReactKeycloakProvider>
  );
};
