import { addDays, format } from 'date-fns';

export const common: Pick<
  AppConfiguration,
  | 'links'
  | 'analyticsEvents'
  | 'wizardItems'
  | 'publicUrl'
  | 'i18n'
  | 'files'
  | 'externalLinks'
  | 'models'
  | 'electricModels'
> = {
  links: {
    legalNotice: '',
    privacyPolicy: '',
    consumerArbitration: '',
    contactEmailAddress: ''
  },
  analyticsEvents: {
    views: {
      initialConfirmationPage: '',
      uploadDocumentsPage: '',
      finalConfirmationPage: '',
      errorPage: '',
      linkExpiredPage: '',
      licensePage: '',
      privacyPolicyPage: '',
      legalTermsPage: '',
      cookiePolicyPage: '',
      contactPage: ''
    },
    subareas: {
      situationalData: '',
      contactData: '',
      termsAndConditions: ''
    }
  },
  wizardItems: ['nav:personalDetails', 'nav:summary'],
  publicUrl: process.env.PUBLIC_URL || '',
  i18n: {
    lng: 'fr',
    ns: [
      'landingPage',
      'summaryPage',
      'errorPage',
      'confirmationPage',
      'applicationPersonInfo',
      'applicationContact',
      'validation',
      'paymentPage'
    ]
  },
  files: {
    dataProtection: 'data_protection.pdf',
    legalTerms: {
      default: 'legal-terms/legal_terms.pdf'
    }
  },
  externalLinks: {
    default: ''
  },
  models: [],
  electricModels: []
};

export interface AppConfiguration {
  links: {
    legalNotice: string;
    privacyPolicy: string;
    consumerArbitration: string;
    contactEmailAddress: string;
  };
  analyticsEvents: {
    views: {
      initialConfirmationPage: string;
      uploadDocumentsPage: string;
      finalConfirmationPage: string;
      errorPage: string;
      linkExpiredPage: string;
      licensePage: string;
      privacyPolicyPage: string;
      legalTermsPage: string;
      cookiePolicyPage: string;
      contactPage: string;
    };
    subareas: {
      situationalData: string;
      contactData: string;
      termsAndConditions: string;
    };
  };
  wizardItems: string[];
  images: {
    logo: string;
    logoSmall: string;
    coBrandingLogo?: string;
  };
  publicUrl: string;
  brand: 'vw6' | 'audi' | 'seat' | 'vw' | 'vwfs' | 'vwcv' | 'cupra';
  i18n: {
    lng: string;
    ns: string[];
  };
  files: {
    dataProtection: string;
    legalTerms: {
      [key: string]: any;
    };
  };
  externalLinks: {
    [key: string]: any;
  };
  models: string[];
  electricModels: string[];
}

export const brandNames: { [key: string]: string } = {
  seat: 'SEAT',
  vw: 'Volkswagen',
  vwcv: 'Volkswagen Véhicules Utilitaires',
  audi: 'Audi',
  cupra: 'SEAT',
  vwfs: 'Volkswagen'
};

export const formatCreationDate = (date: string | any, product?: string) => {
  if (date) {
    return format(addDays(new Date(date), product === 'multiproduct' ? 4 : 2), 'dd/MM/yyyy');
  }
};
