import React, { FunctionComponent } from 'react';
import { Header as BrHeader, Logo as BronsonLogo, Fieldset, Layout, Button } from '@vwfs-bronson/bronson-react';
import { ContactModal } from '../ContactModal/ContactModal';
import HeaderLogout from '../IdentityBroker/HeaderLogout';
import { useLocation } from 'react-router-dom';
import { routes } from '../../routes';

const Header: FunctionComponent = () => {
  const location = useLocation();

  const Logo = function Logo() {
    return <BronsonLogo ariallabel="Logo" tabIndex={0} title="VWFS TCO" />;
  };

  const isLogoutEnabled = (): boolean => {
    return (
      location.pathname.includes(routes.application.forms) || location.pathname.includes(routes.application.summary)
    );
  };

  const faqUrl = 'https://volkswagenabonnement.vwfs.fr/faqs.html';

  return (
    <>
      <Fieldset.Row style={{ marginTop: '10px' }}>
        <Layout right>
          <Layout.Item default="1/12" s="1/1">
            <Button
              secondary
              icon="semantic-faq"
              small
              simple
              link
              onClick={() => {
                window.open(faqUrl, '_blank');
              }}
              type="button"
            >
              FAQ
            </Button>
          </Layout.Item>
          <ContactModal />
          <HeaderLogout isLogoutEnabled={isLogoutEnabled()} />
        </Layout>
      </Fieldset.Row>
      <BrHeader testId="appHeader" staticHeader logo={<Logo />}></BrHeader>
    </>
  );
};

export default Header;
