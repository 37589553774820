import { axios } from './apiClient';
import { DataStorageObject } from '../models/data-storage.model';
import { ContactInfo, PersonalInfo } from '../redux/types';

export type FormDataRequest = {
  transactionId: string;
  data: PersonalInfo | ContactInfo;
  token: string;
  formType: 'personalInfo' | 'contactInfo';
};

export const saveFormData = async ({
  transactionId,
  data,
  token,
  formType
}: FormDataRequest): Promise<{ data?: DataStorageObject; isOnSanctionsLists: boolean }> => {
  const response = await axios.post(`/form-data/${transactionId}/${formType}`, data, {
    headers: {
      Authorization: token
    },
    validateStatus: e => {
      return e === 403 || e === 200;
    }
  });
  if (response.status === 403 && response.data.message === 'User under sanctions') {
    return { isOnSanctionsLists: true };
  }
  if (response.status === 403) {
    throw response;
  }

  return { data: response.data, isOnSanctionsLists: false };
};
