import { axios } from './apiClient';
import { UiLog } from '../models/ui-logs.model';

interface Params {
  transactionId: string;
  token: string;
  messages: UiLog[];
  windowId: string;
}

export const sendUiLogs = async ({ transactionId, token, messages, windowId }: Params): Promise<any> => {
  return await axios
    .post(
      `/ui-logs/${transactionId}`,
      {
        messages: messages,
        windowId: windowId
      },
      {
        headers: {
          Authorization: token
        }
      }
    )
    .catch(() => {
      console.error('Failed to send UI logs');
    });
};
